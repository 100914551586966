var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _vm.title
        ? _c("h5", { staticClass: "mt-2 text-bold" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vue-tab-panel",
        {
          attrs: {
            errors: _vm.errors,
            fields: _vm.fields.impostos,
            show: true,
            tabs: _vm.tabs()
          },
          on: {
            abaSelecionada: function($event) {
              _vm.abaSelecionada = $event
            }
          }
        },
        [
          _c(
            "vue-tab-pane",
            { attrs: { id: "tab-1", active: _vm.abaSelecionada === "icms" } },
            [
              _c("form-icms", {
                attrs: { errors: _vm.errors, fields: _vm.fields.impostos.icms }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vue-tab-pane",
            { attrs: { id: "tab-2", active: _vm.abaSelecionada === "ipi" } },
            [
              _c("form-ipi", {
                attrs: { errors: _vm.errors, fields: _vm.fields.impostos.ipi }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vue-tab-pane",
            { attrs: { id: "tab-3", active: _vm.abaSelecionada === "pis" } },
            [
              _c("form-pis", {
                attrs: { errors: _vm.errors, fields: _vm.fields.impostos.pis }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vue-tab-pane",
            { attrs: { id: "tab-4", active: _vm.abaSelecionada === "cofins" } },
            [
              _c("form-cofins", {
                attrs: {
                  errors: _vm.errors,
                  fields: _vm.fields.impostos.cofins
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vue-tab-pane",
            {
              attrs: {
                id: "tab-5",
                active: _vm.abaSelecionada === "estimativa"
              }
            },
            [
              _c("form-estimativa", {
                attrs: {
                  errors: _vm.errors,
                  fields: _vm.fields.impostos.estimativa
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vue-tab-pane",
            {
              attrs: {
                id: "tab-6",
                active: _vm.abaSelecionada === "importacao"
              }
            },
            [
              _c("form-importacao", {
                attrs: { errors: _vm.errors, fields: _vm.fields }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }