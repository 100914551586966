<template>
    <vue-modal :title="!produto.id ? 'Novo produto' : 'Editar produto'" modal_class="modal-xl">
        <template v-slot:body>
            <vue-loading v-if="(produto.id && loading) && loaded" texto="Carregando dados..."></vue-loading>
            <vue-loading v-if="!loaded" texto="Enviando..."></vue-loading>
            <form v-show="(!produto.id || !loading) && loaded" @submit.prevent="submit" id="form">
                <form-produto :errors="errors" :fields="produto"></form-produto>
            </form>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-danger" @click="recarregar()">Recarregar</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
            <button type="submit" form="form" class="btn btn-primary">Salvar cadastro</button>
        </template>
    </vue-modal>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        props: {
            onClose: {
                type: Function,
                default: () => {},
            },
            produto: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                rotaSalvar: '',
                errors: {},
                success: false,
                loaded: true,
                loading: false
            }
        },
        created() {
            this.rotaSalvar = this.$routes.route('produto.salvar');
        },
        methods: {
            ...mapActions(["fetchProduto"]),
            submit() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};

                    const {id, impostos, ...payload} = {...this.produto};
                    const { importacao, ...outrosImpostos } = impostos;
                    
                    if (id){
                        payload.id = id;
                    }

                    payload.impostos = outrosImpostos;

                    axios.post(this.rotaSalvar, payload).then(response => {
                        this.loaded = true;
                        this.success = true;
                        this.onClose()
                        $('#myModal').modal('hide')
                    }).catch(error => {
                        this.loaded = true;
                        if (error && error.response && error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            },
            limparCampos() {
                this.loaded = false;
                this.success = false;
                this.errors = {};
                this.loading = false;
            },
            recarregar() {
                this.fetchProduto(this.produto.id).then(produto => {
                    Object.entries(produto).forEach(([chave, valor]) => {
                        this.produto[chave] = valor;
                    })
                });
            }
        },
    }
</script>

<style>
    label:not(.form-check-label):not(.custom-file-label) {
        font-weight: 500;
    }
</style>