var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _c("vue-lista", {
        attrs: {
          box_title: "Produtos",
          columns: [
            {
              label: "Produto",
              width: "35%"
            },
            {
              label: "CFOP",
              width: "10%"
            },
            {
              label: "Un.",
              width: "10%"
            },
            {
              label: "Qtd.",
              width: "15%"
            },
            {
              label: "Valor Un. R$",
              width: "15%"
            },
            {
              label: "Total R$",
              width: "15%"
            }
          ],
          "start-line": true,
          dataModel: this.getProdutoModel,
          itens: _vm.produtos
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(ref) {
              var openModal = ref.openModal
              var removeLine = ref.removeLine
              return _vm._l(_vm.produtos, function(data, index) {
                return data.deleted_at === false
                  ? _c("tr", { key: index }, [
                      _c(
                        "td",
                        [
                          _c("vue-select-original", {
                            attrs: {
                              options: Array.from(_vm.listaProdutos),
                              label: "text",
                              reduce: function(item) {
                                return item.id
                              }
                            },
                            on: {
                              input: function($event) {
                                return _vm.onSelectProduto($event, index)
                              }
                            },
                            model: {
                              value: data.produto,
                              callback: function($$v) {
                                _vm.$set(data, "produto", $$v)
                              },
                              expression: "data.produto"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: data.impostos.icms.codigo_cfop,
                              expression: "data.impostos.icms.codigo_cfop"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: data.impostos.icms.codigo_cfop },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                data.impostos.icms,
                                "codigo_cfop",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: data.unidade,
                              expression: "data.unidade"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: data.unidade },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(data, "unidade", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("vue-autonumeric", {
                            attrs: { type: "text" },
                            on: {
                              input: function($event) {
                                return _vm.calcularTotalItem(data)
                              }
                            },
                            model: {
                              value: data.quantidade,
                              callback: function($$v) {
                                _vm.$set(data, "quantidade", $$v)
                              },
                              expression: "data.quantidade"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("vue-autonumeric", {
                            attrs: {
                              name: "subtotal",
                              type: "text",
                              decimalPlaces: 4
                            },
                            on: {
                              input: function($event) {
                                return _vm.calcularTotalItem(data)
                              }
                            },
                            model: {
                              value: data.subtotal,
                              callback: function($$v) {
                                _vm.$set(data, "subtotal", $$v)
                              },
                              expression: "data.subtotal"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("vue-autonumeric", {
                            attrs: {
                              name: "total",
                              type: "text",
                              disabled: true
                            },
                            model: {
                              value: data.total,
                              callback: function($$v) {
                                _vm.$set(data, "total", $$v)
                              },
                              expression: "data.total"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right td_buttons" }, [
                        data.produto
                          ? _c("a", {
                              staticClass:
                                "btn_edit_modal fa fa-pencil-alt fa-2x text-primary",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": "#myModal",
                                "aria-hidden": "true"
                              },
                              on: {
                                click: function($event) {
                                  _vm.produto = data
                                  openModal(data)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "fa fa-trash fa-2x text-danger",
                          attrs: { "aria-hidden": "true" },
                          on: {
                            click: function($event) {
                              return removeLine(data)
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e()
              })
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm.produto
        ? _c("modal-produto", {
            attrs: { produto: _vm.produto, onClose: _vm.onClose }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: {
              type: "button",
              "data-toggle": "modal",
              "data-target": "#myModal"
            },
            on: {
              click: function($event) {
                return _vm.cadastrarProduto()
              }
            }
          },
          [_vm._v("\n      Cadastrar novo\n    ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }