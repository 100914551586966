var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            !_vm.show_consumidor_final
              ? _c("vue-input", {
                  attrs: {
                    label: "Nome completo",
                    required: "vuelidate",
                    name: "nome_estrangeiro",
                    type: "text",
                    errors: _vm.errors,
                    fields: _vm.fields,
                    loading: _vm.loading
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.show_consumidor_final
              ? _c("vue-search-input", {
                  attrs: {
                    label: "Nome completo",
                    required: "vuelidate",
                    name: "nome_estrangeiro",
                    type: "text",
                    errors: _vm.errors,
                    fields: _vm.fields,
                    loading: _vm.loading,
                    searchFields: _vm.clientes,
                    searchKey: "Nome/Razão social",
                    onSelect: _vm.getCliente
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Identificação (Passaporte ou outro documento legal)",
                name: "cpf_cnpj",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "País",
                required: "vuelidate",
                name: "uf",
                options: _vm.paises,
                select_label: "Selecione",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Endereço",
                required: "vuelidate",
                name: "endereco",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Número",
                required: "vuelidate",
                name: "numero",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Complemento",
                name: "complemento",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Bairro",
                required: "vuelidate",
                name: "bairro",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 align-self-end" },
        [
          _vm.showSalvarCliente && _vm.show_consumidor_final
            ? _c("vue-checkbox", {
                attrs: {
                  label: "Salvar cliente",
                  name: "salvar_cliente",
                  value: "2",
                  fields: _vm.fields
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }