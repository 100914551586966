var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "pt-2" }, [
      _vm._v("Informações da Transportadora")
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Razão Social",
                name: "razao_social",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "CNPJ",
                name: "cnpj",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Inscrição estadual (I.E)",
                name: "ie",
                type: "text",
                disabled: _vm.ieIsento,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-1 align-self-center" },
        [
          _c("vue-checkbox", {
            attrs: {
              label: "Isento",
              name: "isento_ie_transportadora",
              value: "0",
              onchange: _vm.setIeIsento,
              fields: _vm.fields
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "CEP",
                name: "cep",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Endereço",
                name: "endereco",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Cidade",
                name: "cidade",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Estado",
                select_label: "Selecione",
                name: "uf",
                options: _vm.estados,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Placa do Veículo",
                name: "placa",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "UF Veículo",
                select_label: "Selecione",
                name: "uf_veiculo",
                options: _vm.estados,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Registro Nacional (ANTT)",
                name: "rntc",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Valor do seguro",
                name: "seguro",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "pt-2 pb-1" }, [_vm._v("Reboque")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Placa do Veículo Reboque",
                tooltip:
                  "Informar em um dos seguintes formatos: XXX9999, XXX999, XX9999 ou XXXX999.",
                name: "reboque_placa",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Estado onde o veículo reboque foi emplacado",
                select_label: "Selecione",
                tooltip:
                  "Informar 'Exterior' se o veículo foi emplacado fora do Brasil.",
                name: "reboque_uf_veiculo",
                options: _vm.estados,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Registro Nacional de Transportador de Carga (ANTT)",
                name: "reboque_rntc",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Identificação do vagão",
                name: "vagao",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Identificação da balsa",
                name: "balsa",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }