var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("h3", { staticClass: "card-title" }, [_vm._v("Produtos")]),
        _vm._v(" "),
        _c("div", { staticClass: "card-tools" }, [
          _c(
            "div",
            {
              staticClass: "input-group input-group-sm",
              staticStyle: { width: "150px" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-block bg-gradient-primary",
                  attrs: {
                    type: "button",
                    "data-toggle": "modal",
                    "data-target": "#myModal"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setProduto(null)
                    }
                  }
                },
                [_vm._v("Cadastrar novo")]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("vue-table", {
        key: _vm.novoCadastro,
        attrs: {
          rota: _vm.$routes.route("produtos"),
          titulos: ["#", "Nome", "Código", "NCM", "Valor"],
          editar: _vm.setProduto
        }
      }),
      _vm._v(" "),
      _c("modal-produto", {
        attrs: { produto: _vm.produto, onClose: _vm.onClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }