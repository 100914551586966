<template>
    <div>
        
        <!-- FILTROS PERSONALIZADOS -->
        <div v-if="Object.keys(predefinedFilters).length" class="row mb-2">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="text-center">
                        <div class="radio">
                            <label><h5><b>Mostrar:</b></h5></label>
                            <label class="radio-inline ml-2" for="one">
                                <input type="radio" id="one" value=0 v-model="picked" @click="cleanFilter()">Todos
                            </label>
                            <label class="radio-inline ml-2" for="two">
                                <input type="radio" id="two" value=1 v-model="picked">Personalizado:
                            </label>
                        </div>
                        <div v-show="picked==true">
                            <label v-for="(filters, index) in predefinedFilters" :key="index" class="checkbox-inline" :for="'checkboxFilter'+index">
                                <input class="checkbox-inline ml-2" type="checkbox" :id="'checkboxFilter'+index"  :value="filters.val" v-model="checkedFilter">
                                {{filters.text}}
                            </label>
                            <button class="btn btn-sm btn-warning ml-2" @click="fetchDataFromApi()">Filtrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mr-0 ml-0">

            <!-- ESQUERDA SUPERIOR DA TABELA -->
            <div class="col-md-4">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row" width="70">
                                <select class="form-control w-auto" id="tableLength" v-model="paramsTable.per_page" @change="changeLength" >
                                    <option v-for="i in selectPerPage" :value="i" :key="i">{{i}}</option>
                                </select>
                                <label for="tableLength" style="margin-left: 10px; margin-block-start: auto;"> resultados por página</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <a class="btn bg-info" @click="exportToExcel">
                                <i class="far fa-file-excel"></i> Excel
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- DIREITA SUPERIOR DA TABELA -->
            <div class="col-md-8">
                <div class="row float-right">
                    <div class="form-group">
                        <a class="btn text-muted" @click="cleanFilters">
                            Limpar filtros
                        </a>
                    </div>
                    <div class="form-group">
                        <select class="form-control" v-model="paramsTable.filter_by" @change="reFilter">
                            <option v-for="i in filterColumns" :value="i.name" :key="i.name">{{i.text}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <select class="form-control" v-model="paramsTable.filter_method" @change="reFilter(false)">
                            <option v-for="i in filterMethods" :value="i.name" :key="i.name">{{i.text}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <select v-if="filter_type == 'select'" class="form-control" v-model="paramsTable.filter" @change="filterInput">
                            <option v-for="(text, value) in filterOptions" :value="value" :key="value">{{text}}</option>
                        </select>
                        <input v-else class="form-control" :type="filter_type" v-model="paramsTable.filter" @input="filterInput">
                    </div>
                </div>
            </div>

        </div>

        <!-- A TABELA -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">

                <table class="table table-striped table-bordered" role="grid" id='tableData'>
                    <thead>
                        <tr>
                            <th v-for="(column, index) in columns" :key="'th'+index" 
                                :width="column.width ? column.width : ''"
                                :class="columnClassHead(column)"
                                :title="column.label"
                                @click="orderTable(column.key)" >
                                <i v-show="activeSort(column.key)" :class="sort_direction_icon"></i>
                                {{column.label}}
                            </th>
                            <th v-if="acoes.ativo" :width="acoes.width" :class="acoes.class ? acoes.class : 'text-center'" class="no_export">
                                {{acoes.label}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, idx) in tableList" v-show="!isLoading" :key="idx">
                            <td v-for="(column, index) in columns" 
                                :key="'td'+index" 
                                :title="column.html ? '' : getValue(item, column.key, column)" 
                                :style="columnStyle(column, item)"
                                :class="columnClass(column, item)"
                            >
                                <span v-if="column.html" v-html="getValue(item, column.key, column)"></span>
                                <span v-else>{{getValue(item, column.key, column)}}</span>
                            </td>
                            <td v-if="acoes.ativo" :style="acoes.style ? acoes.style : ''" class="no_export">
                                <slot :item="item"></slot>
                            </td>
                        </tr>
                        <tr v-show="isLoading">
                            <td :colspan="acoes.ativo ? Object.keys(columns).length + 1 : Object.keys(columns).length" align="center"><h4 class="text-center"><i class="fa fa-spin fa-spinner"></i> Carregando...</h4></td>
                        </tr>
                        <tr v-show="isEmptyTable && !isLoading">
                            <td :colspan="acoes.ativo ? Object.keys(columns).length + 1 : Object.keys(columns).length" align="center"><h4 class="text-center">{{sem_retorno}}</h4></td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>

        <div class="row" v-show="!isEmptyTable">
            <div class="col-md-5">
                <label>Mostrando de {{ tableProps.from }} até {{ tableProps.to }} de {{ tableProps.total}} registros</label>
            </div>
            <div class="col-md-7">
                <ul class="pagination no-margin float-right">
                    <li :class="isFirstPage ? 'disabled' : ''"><a class="table-link" @click="toFirstPage"><span class="fa fa-angle-double-left"></span></a></li>
                    <li :class="isFirstPage ? 'disabled' : ''"><a class="table-link" @click="toPreviousPage"><span class="fa fa-angle-left"></span></a></li>
                    <li v-for="p in displayPages" :class="pageLinkClass(p)" :key="p">
                        <a class="table-link" @click="changePage(p)">{{ p }}</a>
                    </li>
                    <li :class="isLastPage ? 'disabled' : ''"><a class="table-link" @click="toNextPage"><span class="fa fa-angle-right"></span></a></li>
                    <li :class="isLastPage ? 'disabled' : ''"><a class="table-link" @click="toLastPage"><span class="fa fa-angle-double-right"></span></a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {stringify} from 'qs';

    export default {
        props: {
            acoes: {
                type: Object,
                required: false,
                default: function () {
                    return {
                        'ativo': true,
                        'width': '20%',
                        'label': 'Ações'
                    }
                }
            },
            columns: {
                type: Array,
                required: false,
                default: function () {
                    return [
                        {
                            'key': 'id',
                            'label': '#',
                            'width': '5%'
                        },
                    ]
                }
            },
            url: {
                type: String,
                required: true
            },
            tableParams: {
                type: Object,
                required: false,
                default: function () {
                    return {
                        sort: 'id',
                        sort_direction: 'asc',
                        page: 1,
                        per_page: 10,
                        filter_by: 'id',
                        filter: '',
                        filter_method: 'contem'
                    }
                }
            },
            tableProps: {
                type: Object,
                required: false,
                default: function () {
                    return {
                        from: null,
                        last_page: null,
                        to: null,
                        total: null
                    }
                }
            },
            selectPerPage: {
                type: Array,
                required: false,
                default: function () {
                    return [10, 25, 50, 100, 1000]
                }
            },
            filterColumns: {
                type: Array,
                required: false,
                default: function () {
                    return [
                        {
                            name: 'id',
                            text: '#',
                            type: Number
                        },
                    ]
                }
            },
            predefinedFilters: {
                type: Array,
                required: false,
                default: function () {
                    return []
                }
            },
            checkedFilters: {
                type: [Array, Object],
                required: false,
                default: function () {
                    return []
                }
            },
            //modal aberto direto do Vdatatable, ao fechar recarrega a lista
            recarregar: {
                type: Object,
                default: function () {
                    return {value: false}
                },
                required: false,
            },

        },
        data() {
            return {
                tableList: [],
                eachSide: 2,
                isLoading: false,
                statusFilter: false,
                methodList: 0,
                checkedFilter: [],
                picked: 0,
                filter_type: 'text',
                filterMethods: this.methodsForFilter(),
                sort_direction_icon: '',
                filterOptions: {},
                sem_retorno: 'Nenhum dado encontrado',
                local_storage_key: location.href,
                paramsTable: {}
            }
        },
        mounted() {
            if(sessionStorage[this.local_storage_key]) {

                let sessionParams = JSON.parse(sessionStorage[this.local_storage_key]);

                if(sessionParams['paramsTable']) {
                    this.paramsTable = sessionParams['paramsTable'];
                    this.filter_type = this.filterType();
                    this.sort_direction_icon = this.sortDirectionIcon();
                } else {
                    this.paramsTable = _.cloneDeep(this.tableParams);
                }

                if(sessionParams['checkedFilter']) {
                    this.checkedFilter = sessionParams['checkedFilter']
                }

                if(sessionParams['picked']) {
                    this.picked = sessionParams['picked']
                }

            } else {
                this.paramsTable = _.cloneDeep(this.tableParams);

                if(this.checkedFilters.length) {
                    this.checkedFilter = this.checkedFilters;
                    this.picked = '1';
                }
            }
            
            this.fetchDataFromApi();
        },
        computed: {
            isFirstPage() {
                return this.paramsTable.page === 1;
            },
            isLastPage() {
                return this.paramsTable.page === this.tableProps.last_page;
            },
            isEmptyTable() {
                return this.totalPages === 0;
            },
            notEnoughPages() {
                return this.tableProps.last_page < (this.eachSide * 2) + 4;
            },
            totalPages() {
                return this.tableList.length === 0 ? 0 : this.tableProps.last_page;
            },
            windowSize() {
                return (this.eachSide * 2) + 1;
            },
            windowStart() {
                if (this.tableList.length === 0 || this.paramsTable.page <= this.eachSide) {
                    return 1;
                } else if (this.paramsTable.page >= (this.tableProps.last_page - this.eachSide)) {
                    return this.tableProps.last_page - (this.eachSide * 2);
                }
                return this.paramsTable.page - this.eachSide;
            },
            displayPages() {
                let pages = [];
                if (this.notEnoughPages) {
                    for (let i = 1; this.totalPages >= i; i++) {
                        pages.push(i);
                    }
                } else {
                    for (let i = 1; this.windowSize >= i; i++) {
                        pages.push(this.windowStart + i - 1);
                    }
                }
                return pages;
            }
        },
        watch: {
            recarregar: function (val) {
                if (val.value===true) {
                    this.fetchDataFromApi();
                    this.recarregar.value = false;
                }
            }
        },
        methods: {
            storageParams(key, data) {
                sessionStorage[key] = JSON.stringify(data);
            },
            sortDirectionIcon() {
                if (this.paramsTable.sort_direction === 'asc') {
                    return 'fa fa-angle-down pull-right';
                }
                return 'fa fa-angle-up pull-right';
            },
            methodsForFilter(type = String) {
                let methods = [
                    {
                        name: 'contem',
                        text: 'Contém'
                    },
                    {
                        name: 'nao_contem',
                        text: 'Não Contém'
                    },
                    {
                        name: 'igual',
                        text: 'Igual'
                    }
                ]

                if(type == Date || type == 'date') {
                    methods.push({
                        name: 'a_partir',
                        text: 'A partir de'
                    },
                    {
                        name: 'antes',
                        text: 'Antes de'
                    })
                } else if(type != 'select') {
                    methods.push({
                        name: 'comeca',
                        text: 'Começa com'
                    },
                    {
                        name: 'termina',
                        text: 'Termina com'
                    })
                }

                return methods;
            },
            filterType() {
                const column = this.paramsTable.filter_by;
                const columnData = this.filterColumns.filter((f) => f.name === column).shift();
                this.filterMethods = this.methodsForFilter(columnData.type);
                if (columnData.type == Date || columnData.type == 'date') {
                    return 'date';
                } else if(columnData.type == 'select'){
                    this.filterOptions = columnData.options;
                    return columnData.type;
                }
                return 'text';
            },
            reFilter(cleanFilter = true) {
                this.filter_type = this.filterType();

                if(cleanFilter) {
                    this.paramsTable.filter = '';
                }
                this.fetchDataFromApi();
            },
            changeLength() {
                this.paramsTable.page = 1;
                this.fetchDataFromApi();
            },
            filterInput: _.debounce(function () {
                this.fetchDataFromApi();
            }, 350),
            changePage(page) {

                if (page === this.paramsTable.page) {
                    return;
                }

                this.paramsTable.page = page;
                this.fetchDataFromApi();
            },
            toPreviousPage() {
                this.paramsTable.page--;
                this.fetchDataFromApi();
            },
            toFirstPage() {
                this.paramsTable.page = 1;
                this.fetchDataFromApi();
            },
            toNextPage() {
                this.paramsTable.page++;
                this.fetchDataFromApi();
            },
            toLastPage() {
                this.paramsTable.page = this.tableProps.last_page;
                this.fetchDataFromApi();
            },
            pageLinkClass(page) {
                if (page === this.paramsTable.page) {
                    return 'active';
                }
                return '';
            },
            sortColumn(column, sort_direction = null) {
                const sort = this.paramsTable.sort_direction;

                if (this.paramsTable.sort === column) {
                    this.$set(this.paramsTable, 'sort_direction', sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.$set(this.paramsTable, 'sort_direction', 'asc');
                }

                this.sort_direction_icon = this.sortDirectionIcon();
                this.$set(this.paramsTable, 'sort', column);
            },
            orderTable(column) {
                this.sortColumn(column);
                this.fetchDataFromApi();
            },
            activeSort(column) {
                return this.paramsTable.sort === column;
            },
            cleanFilter() {
                this.checkedFilter = [];
                this.fetchDataFromApi();
            },
            cleanFilters() {
                this.paramsTable = _.cloneDeep(this.tableParams);
                this.checkedFilter = [];
                this.picked = 0;
                this.fetchDataFromApi();
            },
            fetchDataFromApi() {
                const vm = this;
                const convert = new URLSearchParams();

                // Armazena os parametros da tabela na sessão
                let sessionParams = {
                    'paramsTable': this.paramsTable,
                    'checkedFilter': this.checkedFilter,
                    'picked': this.picked
                }
                this.storageParams(this.local_storage_key, sessionParams);

                $.each(this.paramsTable, function(key, value) {
                    convert.append(key, value)
                });

                if (this.checkedFilter) {
                    $.each(this.checkedFilter, function(key, value) {
                        convert.append('predefined_filter[]', value)
                    });
                }

                const data = {
                    params: convert,
                    //paramsSerializer: stringify
                };
                this.isLoading = true;

                axios.get(this.url, data)
                    .then(({ data: _data }) => {
                        vm.tableList = _data.data;
                        vm.tableProps.from = _data.from;
                        vm.tableProps.to = _data.to;
                        vm.tableProps.total = _data.total;
                        vm.tableProps.last_page = _data.last_page;
                        vm.isLoading = false;
                    })
                    .catch((error) => {
                        vm.showErrorMessage('Houve um problema ao tentar retornar os dados, caso o problema persista contacte o suporte');
                        vm.sem_retorno = "Falha ao retornar os dados";
                        vm.isLoading = false;
                    });
            },
            dataGet(i,k,d) {
                if (typeof d === 'undefined') { d = null; }
                if (!k) return i;
                var s = k.split('.');
                var o = i;
                for(var x=0;x < s.length; x++) {
                    if (null !== o && o.hasOwnProperty(s[x])){
                        o = o[s[x]];
                    } else {
                        return d;
                    }
                }
                return o;
            },
            getValue(item, key, column) {
                let val = '';
                if(key !== 'all' || key == null || key == undefined) {
                    val = this.dataGet(item, key);
                } else {
                    val = item;
                }
                if(column.hasOwnProperty('method')) {
                    val = column['method'](val);
                }
                return val;
            },
            columnStyle(column, item) {
                if(column.style) {
                    if(typeof column.style == 'function') {
                        return column.style(item);
                    }
                    return column.style;
                }
                return '';
            },
            columnClass(column, item) {
                let classe = '';
                if(column.tdClass) {
                    if(typeof column.tdClass == 'function') {
                        classe = column.tdClass(item);
                    }
                    classe = column.tdClass;
                }
                if(column.export != undefined && !column.export) {
                    classe = classe + ' no_export';
                }
                return classe;
            },
            columnClassHead(column) {
                let classe = '';
                if(column.class) {
                    classe = column.class+' table-header-sort';
                } else {
                    classe = 'text-center table-header-sort';
                }
                if(column.export != undefined && !column.export) {
                    classe = classe + ' no_export';
                }
                return classe;
            },
            exportToExcel() {
                $('#tableData').excelexportjs({
                    containerid: "tableData", 
                    datatype: 'table'
                });
            }
        }
    }
</script>

<style scoped>
    .table-header-sort {
        cursor: pointer;
    }
    .table-link {
        cursor: pointer;
    }
    .pagination > li.disabled .table-link {
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }
    table {
        table-layout: fixed;
        width:100%;
        margin-top: 5px;
    }
    th, td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .form-group {
        margin-bottom: 0rem !important;
    }

    /* PAGINAÇÃO */
    .pagination {
        display: inline-block;
        padding-left: 0;
        /* margin: 20px 0; */
        border-radius: 4px;
    }
    .pagination > li {
        display: inline;
    }
    .pagination > li > a,
    .pagination > li > span {
        position: relative;
        float: left;
        padding: 6px 12px;
        margin-left: -1px;
        line-height: 1.42857143;
        color: #337ab7;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #ddd;
    }
    .pagination > li > a:hover,
    .pagination > li > span:hover,
    .pagination > li > a:focus,
    .pagination > li > span:focus {
        z-index: 2;
        color: #23527c;
        background-color: #eeeeee;
        border-color: #ddd;
    }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
    }
    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > span:focus,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover,
    .pagination > .disabled > a:focus {
        color: #777777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
    }
    .pagination-lg > li > a,
    .pagination-lg > li > span {
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
    }
    .pagination-lg > li:first-child > a,
    .pagination-lg > li:first-child > span {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .pagination-lg > li:last-child > a,
    .pagination-lg > li:last-child > span {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .pagination-sm > li > a,
    .pagination-sm > li > span {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
    }
    .pagination-sm > li:first-child > a,
    .pagination-sm > li:first-child > span {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    .pagination-sm > li:last-child > a,
    .pagination-sm > li:last-child > span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
</style>