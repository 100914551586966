var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("h4", { staticClass: "pt-2" }, [_vm._v("Informações do Pedido")]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Número do pedido",
                name: "ID",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Data de Entrada ou Saída",
                name: "data_entrada_saida",
                type: "date",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Presença*",
                name: "presenca",
                options: _vm.opcoesPresenca,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-2" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Total frete",
                name: "frete",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              },
              on: {
                input: function($event) {
                  return _vm.calcularTotalPedido()
                }
              },
              model: {
                value: _vm.fields["frete"],
                callback: function($$v) {
                  _vm.$set(_vm.fields, "frete", $$v)
                },
                expression: "fields['frete']"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Total desconto",
                name: "desconto",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              },
              on: {
                input: function($event) {
                  return _vm.calcularTotalPedido()
                }
              },
              model: {
                value: _vm.fields["desconto"],
                callback: function($$v) {
                  _vm.$set(_vm.fields, "desconto", $$v)
                },
                expression: "fields['desconto']"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Outras despesas acessórias",
                name: "despesas_acessorias",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              },
              on: {
                input: function($event) {
                  return _vm.calcularTotalPedido()
                }
              },
              model: {
                value: _vm.fields["despesas_acessorias"],
                callback: function($$v) {
                  _vm.$set(_vm.fields, "despesas_acessorias", $$v)
                },
                expression: "fields['despesas_acessorias']"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Total pedido",
                name: "total",
                placeholder: "0,00",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                value: _vm.fields.total
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm.emissao == "importacao"
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-input", {
                  attrs: {
                    label: "Despesas aduaneiras (Siscomex)",
                    name: "despesas_aduaneiras",
                    required: "vuelidate",
                    type: "text",
                    errors: _vm.errors,
                    fields: _vm.fields
                  },
                  on: {
                    input: function($event) {
                      return _vm.calcularTotalPedido()
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-textarea", {
              attrs: {
                label: "Informações complementares",
                name: "informacoes_complementares",
                rows: "3",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-textarea", {
              attrs: {
                label: "Informações ao fisco",
                name: "informacoes_fisco",
                rows: "3",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }