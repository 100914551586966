<template>
  <div
    :class="active === true ? 'tab-pane fade active show' : 'tab-pane fade'"
    :id="'content-' + id"
    role="tabpanel"
    :aria-labelledby="id"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "active",
  ],
};
</script>