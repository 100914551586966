<template>
    <div>
        <label :for="name">{{ this.labelRequired }}</label>
        <slot></slot>
        <div v-if="errors && errors[name]" class="text-danger">{{ errors[name][0] }}</div>  
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
            errors: {
                type: Object,
                default: () => ({ }),
            }
        },
        computed: {
            labelRequired(){
                return this.required ? this.label+'*' : this.label;
            }
        }
    }
</script>