var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.title
      ? _c("h6", { staticClass: "mt-2 text-bold" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "nav nav-tabs",
        class: _vm.ul_class,
        attrs: { id: "custom-content-above-tab", role: "tablist" }
      },
      _vm._l(_vm.tabs, function(content, key) {
        return content["show"] === true
          ? _c("li", { key: key, staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  class: content["active"] ? "nav-link active" : "nav-link",
                  attrs: {
                    id: key,
                    "data-toggle": "pill",
                    href: "#content-" + key,
                    role: "tab",
                    "aria-controls": "custom-content-above-home",
                    "aria-selected": content["active"] ? true : false
                  },
                  on: {
                    click: function($event) {
                      return _vm.notificarAbaSelecionada(key)
                    }
                  }
                },
                [
                  content["tab_icon"]
                    ? _c("i", { class: content["tab_icon"] })
                    : _vm._e(),
                  _vm._v(
                    " \n                " +
                      _vm._s(content["label"]) +
                      "\n            "
                  )
                ]
              )
            ])
          : _vm._e()
      }),
      0
    ),
    _vm._v(" "),
    _vm.fix_content
      ? _c("div", { staticClass: "tab-custom-content" }, [
          _c("p", { staticClass: "lead mb-0" }, [
            _vm._v(_vm._s(_vm.fix_content))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "tab-content",
        attrs: { id: "custom-content-above-tabContent" }
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }