var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-modal", {
    attrs: {
      title: !_vm.cliente_id ? "Novo cliente" : "Editar cliente",
      modal_class: "modal-lg"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "form",
              {
                attrs: { id: "form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.cliente_id && _vm.loaded,
                        expression: "!cliente_id && loaded"
                      }
                    ],
                    staticClass: "row"
                  },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group ml-2" },
                        [
                          _c("vue-radio", {
                            attrs: {
                              name: "tipo_pessoa",
                              options: _vm.tipos_pessoa,
                              onchange: _vm.changeRoute,
                              inline: true,
                              errors: _vm.errors,
                              fields: _vm.fields
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                ((!_vm.cliente_id && _vm.loading) ||
                  (_vm.cliente_id && !_vm.loading)) &&
                _vm.loaded
                  ? _c("router-view", {
                      attrs: { errors: _vm.errors, fields: _vm.fields }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.cliente_id && _vm.loading && _vm.loaded
                  ? _c("vue-loading", {
                      attrs: { texto: "Carregando dados..." }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loaded
                  ? _c("vue-loading", { attrs: { texto: "Enviando..." } })
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("Fechar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", form: "form" }
              },
              [_vm._v("Salvar")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }