var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("h4", { staticClass: "pt-2" }, [_vm._v("Fatura")]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Número da fatura",
                name: "numero",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Valor original",
                name: "valor",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              },
              model: {
                value: _vm.fields["valor"],
                callback: function($$v) {
                  _vm.$set(_vm.fields, "valor", $$v)
                },
                expression: "fields['valor']"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Valor líquido",
                name: "valor_liquido",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              },
              model: {
                value: _vm.fields["valor_liquido"],
                callback: function($$v) {
                  _vm.$set(_vm.fields, "valor_liquido", $$v)
                },
                expression: "fields['valor_liquido']"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-autonumeric", {
              attrs: {
                label: "Desconto",
                name: "desconto",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              },
              model: {
                value: _vm.fields["desconto"],
                callback: function($$v) {
                  _vm.$set(_vm.fields, "desconto", $$v)
                },
                expression: "fields['desconto']"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }