var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 mb-n2" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-2 text-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-app",
              attrs: { to: { name: "nota-fiscal" } }
            },
            [
              _c("i", { staticClass: "fas fa-inbox" }),
              _vm._v("\n                Notas fiscais\n            ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 text-center" },
        [
          _c(
            "router-link",
            { staticClass: "btn btn-app", attrs: { to: { name: "emitir" } } },
            [
              _c("i", { staticClass: "far fa-file-alt" }),
              _vm._v("\n                Emitir notas\n            ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 text-center" },
        [
          _c(
            "router-link",
            { staticClass: "btn btn-app", attrs: { to: { name: "cliente" } } },
            [
              _c("i", { staticClass: "far fa-address-book" }),
              _vm._v("\n                Clientes\n            ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 text-center" },
        [
          _c(
            "router-link",
            { staticClass: "btn btn-app", attrs: { to: { name: "produto" } } },
            [
              _c("i", { staticClass: "fas fa-shopping-cart" }),
              _vm._v("\n                Produtos\n            ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 text-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-app",
              attrs: { to: { name: "relatorios" } }
            },
            [
              _c("i", { staticClass: "far fa-chart-bar" }),
              _vm._v("\n                Relatórios\n            ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 text-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-app",
              attrs: { to: { name: "configuracao" } }
            },
            [
              _c("i", { staticClass: "fas fa-cog" }),
              _vm._v("\n                Configurações\n            ")
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }