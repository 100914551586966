var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { attrs: { for: _vm.name } }, [
          _vm._v(_vm._s(_vm.required ? _vm.label + "*" : _vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.tooltip
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip:top",
              value: _vm.tooltip,
              expression: "tooltip",
              arg: "top"
            }
          ],
          staticClass: "fa fa-question-circle"
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fields[_vm.name],
              expression: "fields[name]"
            }
          ],
          staticClass: "form-control",
          class: [
            { "border-danger": _vm.errors && _vm.errors[_vm.name] },
            _vm.classes
          ],
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.id !== undefined ? _vm.name + "_" + _vm.id : _vm.name,
            required: _vm.required && _vm.required != "vuelidate",
            disabled: _vm.disabled || _vm.loading,
            autocomplete: "nope",
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.fields[_vm.name])
              ? _vm._i(_vm.fields[_vm.name], null) > -1
              : _vm.fields[_vm.name]
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.fields[_vm.name],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.fields, _vm.name, $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.fields,
                        _vm.name,
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.fields, _vm.name, $$c)
                }
              },
              _vm.change
            ]
          }
        })
      : _vm.type === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fields[_vm.name],
              expression: "fields[name]"
            }
          ],
          staticClass: "form-control",
          class: [
            { "border-danger": _vm.errors && _vm.errors[_vm.name] },
            _vm.classes
          ],
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.id !== undefined ? _vm.name + "_" + _vm.id : _vm.name,
            required: _vm.required && _vm.required != "vuelidate",
            disabled: _vm.disabled || _vm.loading,
            autocomplete: "nope",
            type: "radio"
          },
          domProps: { checked: _vm._q(_vm.fields[_vm.name], null) },
          on: {
            change: [
              function($event) {
                return _vm.$set(_vm.fields, _vm.name, null)
              },
              _vm.change
            ]
          }
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fields[_vm.name],
              expression: "fields[name]"
            }
          ],
          staticClass: "form-control",
          class: [
            { "border-danger": _vm.errors && _vm.errors[_vm.name] },
            _vm.classes
          ],
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.id !== undefined ? _vm.name + "_" + _vm.id : _vm.name,
            required: _vm.required && _vm.required != "vuelidate",
            disabled: _vm.disabled || _vm.loading,
            autocomplete: "nope",
            type: _vm.type
          },
          domProps: { value: _vm.fields[_vm.name] },
          on: {
            change: _vm.change,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.fields, _vm.name, $event.target.value)
            }
          }
        }),
    _vm._v(" "),
    _vm.optionsFields &&
    !_vm.isEmptyObject(_vm.optionsFields) &&
    !_vm.optionSelected
      ? _c(
          "div",
          { staticClass: "autocomplete-menu" },
          _vm._l(_vm.optionsFields, function(option, index) {
            return _c(
              "div",
              {
                key: index,
                staticStyle: { "background-color": "transparent" },
                on: {
                  click: function($event) {
                    return _vm.getData(option)
                  }
                }
              },
              [_vm._v(_vm._s(option[_vm.searchKey]))]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.errors && _vm.errors[_vm.name]
      ? _c("div", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errors[_vm.name][0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }