var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-modal", {
    attrs: { title: "Tributação", modal_class: "modal-lg" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "form",
              {
                attrs: { id: "form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c("form-produto", {
                  attrs: {
                    errors: _vm.errors,
                    fields: _vm.produtos,
                    loading: true
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("Fechar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", form: "form" }
              },
              [_vm._v("Salvar")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }