<template>
    <div class="row">
        <div class="col-12">

            <vue-retorno-api :retorno="retorno"></vue-retorno-api>

            <vue-datatable
                :url="'nota-fiscal/datatable'"
                :columns="[
                    {
                        'key': 'id',
                        'label': '#',
                        'width': '5%'
                    },
                    {
                        'key': 'ambiente',
                        'label': 'Ambiente',
                        'width': '10%',
                        'html': true,
                        'method': ambienteEmissao
                    },
                    {
                        'key': 'numero',
                        'label': 'Número',
                        'width': '6%',
                        'method': numero => Number(numero) ? numero : '-',
                    },
                    {
                        'key': 'finalidade',
                        'label': 'Finalidade',
                        'width': '11%',
                        'method': finalidadeNota
                    },
                    {
                        'key': 'operacao',
                        'label': 'Operação',
                        'width': '7%',
                        'method': getOpcaoOperacaoText
                    },
                    {
                        'key': 'natureza_operacao',
                        'label': 'Natureza',
                        'width': '7%',
                        'method': natureza => natureza || '-',
                    },
                    {
                        'key': 'cliente',
                        'label': 'Cliente',
                        'width': '20%',
                        'method': cliente => cliente || '-',
                    },
                    {
                        'key': 'valor',
                        'label': 'Valor',
                        'width': '8%',
                        'method': maskFloat
                    },
                    {
                        'key': 'data',
                        'label': 'Data',
                        'width': '10%',
                        'method': dateBR || '-',
                    },
                    {
                        'key': 'status',
                        'label': 'Status',
                        'width': '10%',
                        'html': true,
                        'method': statusBadge
                    },
                ]"
                :acoes="{
                    'ativo': true,
                    'width': '6%',
                    'label': 'Ações',
                    'style': 'overflow: visible;'
                }"
                :filterColumns="[
                    {
                        name: 'id',
                        text: 'ID',
                        type: Number
                    },
                    {
                        name: 'dados_formulario->operacao->ambiente',
                        text: 'Ambiente',
                        type: 'select',
                        options: {
                            '1': 'Produção',
                            '2': 'Homologação'
                        }
                    },
                    {
                        name: 'dados_retorno->nfe',
                        text: 'Número',
                        type: Number
                    },
                    {
                        name: 'dados_envio->finalidade',
                        text: 'Finalidade',
                        type: 'select',
                        options: {
                            '1': 'NF-e normal',
                            '2': 'NF-e complementar',
                            '3': 'NF-e de ajuste',
                            '4': 'Devolução/Retorno',
                            '5': 'Carta de Correção',
                            '6': 'Inutilizar Numeração',
                        }
                    },
                    {
                        name: 'dados_envio->operacao',
                        text: 'Operação',
                        type: 'select',
                        options: {
                            '0': 'Entrada',
                            '1': 'Saída',
                            'importacao': 'Importação'
                        }
                    },
                    {
                        name: 'dados_envio->natureza_operacao',
                        text: 'Natureza',
                        type: String
                    },
                    {
                        name: 'dados_envio->cliente->nome',
                        text: 'Cliente',
                        type: String
                    },
                    {
                        name: 'dados_formulario->pedido->total',
                        text: 'Valor',
                        type: Number
                    },
                    {
                        name: 'dados_retorno->log->dhRecbto',
                        text: 'Data',
                        type: Date
                    },
                    {
                        name: 'dados_retorno->status',
                        text: 'Status',
                        type: 'select',
                        options: {
                            'aprovado': 'Aprovado',
                            'reprovado': 'Reprovado'
                        }
                    },
                ]"
                :predefinedFilters="[
                    {
                        val: 1,
                        text: 'Ambiente de Produção',
                        type: Number
                    },
                    {
                        val: 2,
                        text: 'Ambiente de Homologação',
                        type: Number
                    }
                ]"
                :tableParams="{
                    sort: 'id',
                    sort_direction: 'desc',
                    page: 1,
                    per_page: 10,
                    filter_by: 'id',
                    filter: '',
                    filter_method: 'contem'
                }"
                :checkedFilters="[1]"
            >
            <template slot-scope="{item}">
                <div v-if="item.cancelada == false && item.status == 'aprovado'" class="dropdown text-center">
                    <button class="btn pb0 p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" @click="setNota(item.id, item.chave, 'devolucao')" v-if="item.finalidade !== '5'" data-toggle="modal" data-target="#myModalDevolucao">Emitir devolução</a>
                        <a class="dropdown-item" @click="setNota(item.id, item.chave, 'cancelamento')" data-toggle="modal" data-target="#myModal">Cancelar</a>
                        <a class="dropdown-item" :href="item.danfe" v-if="item.danfe" target="_blank">DANFE</a>
                        <a class="dropdown-item" :href="item.xml" v-if="item.xml" target="_blank">XML</a>
                    </div>
                </div>
            </template>
            </vue-datatable>
        </div>
        <modal-cancelamento v-if="showModal == 'cancelamento'" :key="idNota" :idNota="idNota" :chaveNota="chaveNota" :onClose="onClose"></modal-cancelamento>
        <modal-devolucao v-if="showModal == 'devolucao'" :key="idNota" :idNota="idNota" :chaveNota="chaveNota" :onClose="onClose"></modal-devolucao>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data() {
            return {
                idNota: null,
                chaveNota: null,
                retorno: null,
                status: '',
                nota: {},
                reload: 0,
                showModal: null
            }
        },
        computed: mapState({
            notas: state => state.nota.notas,
            opcoesOperacao: state => state.options.opcoesOperacao,
            loading: state => state.nota.loading
        }),
        mounted() {
            this.getResults()
        },
        methods: {
            setNota(id, chave, modal) {
                this.setShowModal(modal)
                this.idNota = id
                this.chaveNota = chave
            },
            setShowModal(modal) {
                this.showModal = modal
            },
            onClose(retorno) {
                this.retorno = retorno
                this.reload++
            },
            getResults(page = 1) {
                this.$store.dispatch('getNotasFiscais', page)
            },
            finalidadeNota(finalidade) {
                switch(finalidade) {
                    case '1':
                        return 'NF-e normal'
                    case '2':
                        return 'NF-e complementar'
                    case '3':
                        return 'NF-e de ajuste'
                    case '4':
                        return 'Devolução/Retorno'
                    case '5':
                        return 'Carta de Correção'
                    case '6':
                        return 'Inutilizar Numeração'
                    default:
                        return '-'
                }
            },
            getOpcaoOperacaoText(opcaoOperacaoValue) {
                return this.opcoesOperacao[opcaoOperacaoValue] || '-';
            },
            statusBadge(status) {
                switch(status) {
                    case 'aprovado':
                        return '<span class="badge bg-success text-capitalize">'+status+'</span>';
                    case 'reprovado':
                        return '<span class="badge bg-danger text-capitalize">'+status+'</span>';
                    case 'cancelado': 
                        return '<span class="badge bg-dark text-capitalize">'+status+'</span>';
                    case 'contingencia': 
                        return '<span class="badge bg-warning text-capitalize">'+status+'</span>';
                    case 'processamento': 
                        return '<span class="badge bg-warning text-capitalize">'+status+'</span>';
                    default:
                        return '-'
                }
            },
            ambienteEmissao(ambiente) {
                switch(Number(ambiente)) {
                    case 1 :
                        return '<span class="badge bg-olive text-capitalize">Produção</span>';
                    case 2:
                        return '<span class="badge bg-secondary text-capitalize">Homologação</span>';
                    default:
                        return '-'
                }
            }
        }
    }
</script>

