<template>
    <div
        v-if="retorno != undefined && retorno != null"
        :class="classRetorno"
    >

        <div class="text-bold text-capitalize">
            {{retorno.status}}
        </div>

        <div v-if="retorno.error">
            {{retorno.error}}
        </div>
        
        <div v-if="retorno.log != undefined && retorno.log != null">
            {{retorno.log.aProt ? retorno.log.aProt[0].xMotivo : retorno.log.xMotivo}}
        </div>

    </div>
</template>

<script>
export default {
    props: ['retorno'],
    computed: {
        classRetorno(){
            const error = 'alert alert-default-danger';
            const success = 'alert alert-default-success';
            if (!this.retorno){
                return error;
            }

            const { status, tipo, log } = this.retorno;
            if (status == 'aprovado' || status == 'cancelado'){
                return success;
            }

            if (tipo == "inutilizarNumeracao" && log && log.xMotivo == "Inutilizacao de numero homologado"){
                return success;
            }
            return error;
        }
    }
}
</script>
