var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { attrs: { for: _vm.name } }, [
          _vm._v(_vm._s(_vm.required ? _vm.label + "*" : _vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.tooltip
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip:top",
              value: _vm.tooltip,
              expression: "tooltip",
              arg: "top"
            }
          ],
          staticClass: "fa fa-question-circle"
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control",
      attrs: {
        type: "text",
        "data-a-dec": _vm.decimalSeparator,
        "data-a-sep": _vm.thousandSeparator,
        "data-m-dec": _vm.decimalPlaces,
        readonly: _vm.readonly,
        required: _vm.required,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder
      },
      on: {
        blur: function($event) {
          return _vm.$emit("input", _vm.rawValue)
        }
      }
    }),
    _vm._v(" "),
    _vm.errors && _vm.errors[_vm.name]
      ? _c("div", { staticClass: "text-danger" }, [
          _vm._v("\n    " + _vm._s(_vm.errors[_vm.name][0]) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }