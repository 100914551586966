var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c("h4", { staticClass: "pt-2" }, [_vm._v("Informações de Transporte")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-select", {
                attrs: {
                  label: "Modalidade do frete*",
                  name: "modalidade_frete",
                  options: _vm.opcoesModalidadeFrete,
                  onchange: _vm.selectModalidadeFrete,
                  show_key: true,
                  errors: _vm.errors,
                  fields: _vm.pedido
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm.ocorrenciaTransporte
                ? _c("vue-select", {
                    attrs: {
                      label: "Forma de envio",
                      name: "forma_envio",
                      options: _vm.opcoesFormasEnvio,
                      onchange: _vm.selectFormaEnvio,
                      errors: _vm.errors,
                      fields: _vm.fields
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.ocorrenciaTransporte
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-autonumeric", {
                    attrs: {
                      label: "Total frete",
                      name: "frete",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.pedido
                    },
                    model: {
                      value: _vm.pedido["frete"],
                      callback: function($$v) {
                        _vm.$set(_vm.pedido, "frete", $$v)
                      },
                      expression: "pedido['frete']"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Qtd. Volumes",
                      name: "volume",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.transporte
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-autonumeric", {
                    attrs: {
                      label: "Peso bruto",
                      name: "peso_bruto",
                      errors: _vm.errors,
                      fields: _vm.transporte,
                      decimalPlaces: 3
                    },
                    model: {
                      value: _vm.transporte["peso_bruto"],
                      callback: function($$v) {
                        _vm.$set(_vm.transporte, "peso_bruto", $$v)
                      },
                      expression: "transporte['peso_bruto']"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-autonumeric", {
                    attrs: {
                      label: "Peso líquido",
                      name: "peso_liquido",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.transporte,
                      decimalPlaces: 3
                    },
                    model: {
                      value: _vm.transporte["peso_liquido"],
                      callback: function($$v) {
                        _vm.$set(_vm.transporte, "peso_liquido", $$v)
                      },
                      expression: "transporte['peso_liquido']"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ocorrenciaTransporte
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Espécie",
                      name: "especie",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.transporte
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Marca",
                      name: "marca",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.transporte
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Numeração",
                      name: "numeracao",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.transporte
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Número dos lacres",
                      name: "lacres",
                      type: "text",
                      errors: _vm.errors,
                      fields: _vm.transporte
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ocorrenciaTransporte && _vm.formaEnvio === "transportadora"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("vue-checkbox", {
                  attrs: {
                    label: "Informar dados da transportadora",
                    name: "show_form_transportadora",
                    value: "show",
                    onchange: _vm.selectInformarDadosTransportadora,
                    fields: _vm.fields
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.show_form_transportadora && _vm.ocorrenciaTransporte
        ? _c("form-transportadora", {
            attrs: { errors: _vm.errors, fields: _vm.transporte }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }