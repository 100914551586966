var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _c("form-pedido", { attrs: { fields: _vm.pedido, errors: _vm.errors } }),
      _vm._v(" "),
      _c("vue-append", {
        attrs: {
          component: "form-pagamento",
          button_text: "Adicionar meio de pagamento",
          fields: _vm.pedido["pagamento"],
          errors: _vm.errors
        }
      }),
      _vm._v(" "),
      _c("form-fatura", {
        attrs: { fields: _vm.pedido["fatura"], errors: _vm.errors }
      }),
      _vm._v(" "),
      _c("vue-append", {
        attrs: {
          component: "form-parcela",
          button_text: "Adicionar parcela",
          fields: _vm.pedido["parcelas"],
          errors: _vm.errors
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }