var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12", attrs: { id: _vm.id } }, [
    _vm.key == 0
      ? _c("h4", { staticClass: "pt-2" }, [_vm._v("Formas de pagamento")])
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _vm.key != 0
        ? _c("div", { staticClass: "row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm mr-n2 ribbon-wrapper",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.removeRow(_vm.key)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-trash" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-select", {
                attrs: {
                  label: "Meio de pagamento*",
                  show_key: true,
                  text_key: "nome",
                  name: "forma_pagamento",
                  options: _vm.opcoesMeioPagamento,
                  onchange: _vm.selectMeioPagamento,
                  errors: _vm.errors["pagamento"]
                    ? _vm.errors["pagamento"][_vm.key]
                    : {},
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _vm.meioPagamentoCampos.includes("pagamento")
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "Forma de pagamento*",
                      name: "pagamento",
                      options: _vm.opcoesPagamento,
                      errors: _vm.errors["pagamento"]
                        ? _vm.errors["pagamento"][_vm.key]
                        : {},
                      fields: _vm.fields
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _vm.meioPagamentoCampos.includes("valor_pagamento")
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Valor do pagamento (opcional)",
                      name: "valor_pagamento",
                      type: "text",
                      errors: _vm.errors["pagamento"]
                        ? _vm.errors["pagamento"][_vm.key]
                        : {},
                      fields: _vm.fields
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _vm.meioPagamentoCampos.includes("bandeira")
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "Bandeira (opcional)",
                      name: "bandeira",
                      options: _vm.opcoesBandeira,
                      errors: _vm.errors["pagamento"]
                        ? _vm.errors["pagamento"][_vm.key]
                        : {},
                      fields: _vm.fields
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _vm.meioPagamentoCampos.includes("cnpj_credenciadora")
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "Credenciadora (opcional)",
                      name: "cnpj_credenciadora",
                      options: _vm.opcoesCredenciadora,
                      errors: _vm.errors["pagamento"]
                        ? _vm.errors["pagamento"][_vm.key]
                        : {},
                      fields: _vm.fields
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _vm.meioPagamentoCampos.includes("autorizacao")
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("vue-input", {
                    attrs: {
                      label: "Número da autorização - NSU (opcional)",
                      name: "autorizacao",
                      type: "text",
                      errors: _vm.errors["pagamento"]
                        ? _vm.errors["pagamento"][_vm.key]
                        : {},
                      fields: _vm.fields
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }