<template>
    <div class="mt-3">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <vue-label label="Tributação*" name="tipo_tributacao" :errors="errors">
                        <vue-select-original
                            :value="fields.tipo_tributacao"
                            @input="setTipoTributacao($event)"
                            :options="opcoesSituacaoTributaria"
                            label="label"
                            :reduce="(item) => item.value"
                        />
                    </vue-label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <vue-input label="CFOP" name="codigo_cfop" type="text" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <vue-label label="Situação tributária*" name="situacao_tributaria" :errors="errors">
                        <vue-select-original
                            :value="fields.situacao_tributaria"
                            @input="selectSituacaoTributaria($event)"
                            :options="opcoesSituacaoTributariaSelecionada"
                            label="label"
                            :reduce="(item) => item.value"
                        />
                    </vue-label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <vue-select label="Indústria" name="industria" :options="opcoesIndustria" tooltip="Informe caso a venda do produto seja destinada a uma indústria para o uso e consumo final." :errors="errors" :fields="fields"></vue-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <vue-input label="Alíquota da redução da base de cálculo ICMS" name="aliquota_reducao" placeholder="0,00" value="0" :required="true" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <vue-input label="Alíquota da redução da base de cálculo ICMS ST" name="aliquota_reducao_st" placeholder="0,00" value="0" :required="true" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-6" v-if="showTabImportacao">
                <div class="form-group">
                    <vue-input label="Alíquota de ICMS + FCP de importação" name="aliquota_importacao" value="0" tooltip="Para o cálculo correto é necessário informar a alíquota integral do ICMS, sendo o FCP para o estado de destino calculado automaticamente." placeholder="0,00" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-6" v-if="situacaoTributariaCampos.includes('alq_credito')">
                <div class="form-group">
                    <vue-input label="Alíquota aplicável de cálculo de crédito" name="alq_credito" placeholder="0,00" :required="true" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-6" v-if="situacaoTributariaCampos.includes('alq_mva')">
                <div class="form-group">
                    <vue-input label="Percentual do MVA/IVA Original" tooltip="A definição da MVA Ajustada é realizada automaticamente." name="alq_mva" placeholder="0,00" :required="true" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-6" v-if="situacaoTributariaCampos.includes('motivo_desoneracao')">
                <div class="form-group">
                    <vue-select label="Motivo da desoneração" tooltip="Escolha um motivo somente se enquadrar em algum dos listados." name="motivo_desoneracao" select_label="--" :options="this.opcoes['motivos_desoneracao']" :errors="errors" :fields="fields"></vue-select>
                </div>
            </div>
            <div class="col-6" v-if="situacaoTributariaCampos.includes('aliquota_diferimento')">
                <div class="form-group">
                    <vue-input label="Percentual do diferimento" name="aliquota_diferimento" placeholder="0,00" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <vue-input label="Alíquota de importação" name="aliquota_importacao" placeholder="0,00" :errors="errors" :fields="fields"></vue-input>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="Alíquota de MVA" name="aliquota_mva" placeholder="0,00" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="Alíquota efetiva ICMS ST" name="aliquota_efetiva_icms_st" placeholder="0,00" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    export default {
        props: ['fields', 'errors'],
        data() {
            return {
                situacaoTributariaCampos: [],
                opcoesIndustria : {
                    0 : '0 - Não',
                    1 : '1 - Sim'
                },
                porcentage: {
                    decimal: '.',
                    thousands: ',',
                    suffix: ' %',
                    precision: 2,
                },
                ...mapGetters(['getTributacaoIcms']),
                showTabImportacao: false,
            }
        },
        watch: {
            situacao_tributaria(situacaoTributaria) {
                this.situacaoTributariaCampos = [];
                const tipoTributacao = this.fields.tipo_tributacao;
                if (tipoTributacao && situacaoTributaria){
                    const camposTipoTributacao = this.opcoes.situacao_tributaria[tipoTributacao];
                    if (camposTipoTributacao){
                        const camposSituacaoTributaria = camposTipoTributacao[situacaoTributaria];
                        if (camposSituacaoTributaria && camposSituacaoTributaria.campos){
                            this.situacaoTributariaCampos = [...camposSituacaoTributaria.campos];
                        }
                    }
                }
            },
        },
        computed: {
            situacao_tributaria(){
                return this.fields.situacao_tributaria;
            },
            ...mapState({
                opcoes: state => state.options.opcoesFormIcms,
                // showTabImportacao: state => state.nota.showTabImportacao,
            }),
            opcoesSituacaoTributaria(){
                return Object
                        .entries(this.getTributacaoIcms())
                        .map(([value, label]) => ({
                            value,
                            label: label.nome,
                            padrao: !!label.padrao,
                        }));
            },
            opcoesSituacaoTributariaSelecionada(){
                if (this.fields.tipo_tributacao) {
                    return Object.entries(this.opcoes['situacao_tributaria'][this.fields.tipo_tributacao]).map(([value, label]) => ({
                        value,
                        label: label.nome,
                    }))
                }
                return [];
            },
        },
        beforeUpdate() {
            if (!this.fields.tipo_tributacao){
                const tributacaoPadrao = this.opcoesSituacaoTributaria.find(situacao => situacao.padrao).value;
                Vue.set(this.fields, 'tipo_tributacao', tributacaoPadrao);
            }
        },
        methods: {
            setTipoTributacao(tipoTributacao) {
                this.fields.tipo_tributacao = tipoTributacao;
                this.fields.situacao_tributaria = null;
            },
            selectSituacaoTributaria(situacaoTributaria){
                this.fields.situacao_tributaria = situacaoTributaria;
            }
        }
    }
</script>