var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-modal", {
    ref: "formContainer",
    attrs: {
      title: "Emitir devolução",
      modal_class: "modal-lg",
      id: "myModalDevolucao"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "form",
              {
                attrs: { id: "formDevolucao" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("vue-input", {
                          attrs: {
                            label: "Natureza da Operação",
                            required: true,
                            name: "natureza_operacao",
                            type: "text",
                            value: "Devolução de mercadoria",
                            errors: _vm.errors,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("vue-input", {
                          attrs: {
                            label: "CFOP",
                            required: true,
                            name: "cfop",
                            type: "text",
                            errors: _vm.errors,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("vue-input", {
                          attrs: {
                            label: "Volume",
                            name: "volume",
                            type: "text",
                            tooltip:
                              "Quantidade de volumes transportados (Opcional)",
                            errors: _vm.errors,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "produto" } }, [
                        _vm._v("Produtos inclusos na devolução")
                      ]),
                      _vm._v(" "),
                      !_vm.isEmptyObject(_vm.produtos)
                        ? _c(
                            "div",
                            _vm._l(_vm.produtos, function(produto, index) {
                              return _c(
                                "div",
                                { key: "produto_" + index, staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-8" },
                                    [
                                      _c("vue-checkbox", {
                                        attrs: {
                                          label: produto["nome"],
                                          name: index,
                                          value: index,
                                          checked: true,
                                          fields: _vm.fields.produtos.ativo
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.fields.produtos.ativo[index],
                                          expression:
                                            "fields.produtos.ativo[index]"
                                        }
                                      ],
                                      staticClass: "col-md-4"
                                    },
                                    [
                                      _c("vue-input", {
                                        attrs: {
                                          placeholder:
                                            "Qnt. (" +
                                            produto.unidade +
                                            ") ( Máx: " +
                                            produto["quantidade"] +
                                            " )",
                                          name: index,
                                          type: "text",
                                          errors: _vm.errors,
                                          fields: _vm.fields.produtos.quantidade
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("vue-loading", {
                                attrs: { texto: "Buscando produtos..." }
                              })
                            ],
                            1
                          )
                    ])
                  ])
                ])
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("Fechar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", form: "formDevolucao" }
              },
              [_vm._v("Salvar")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }