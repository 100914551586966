var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12", attrs: { id: _vm.id } }, [
    _vm.key == 0
      ? _c("h4", { staticClass: "pt-2" }, [_vm._v("Parcelas")])
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _vm.key != 0
        ? _c("div", { staticClass: "row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm mr-n2 ribbon-wrapper",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.removeRow(_vm.key)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-trash" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                attrs: {
                  label: "Data de vencimento",
                  name: "vencimento",
                  type: "date",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-autonumeric", {
                attrs: {
                  label: "Valor",
                  name: "valor",
                  type: "text",
                  errors: _vm.errors,
                  required: true
                },
                model: {
                  value: _vm.fields["valor"],
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "valor", $$v)
                  },
                  expression: "fields['valor']"
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }