var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-body" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table m-0" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.unidades, function(unidade) {
              return _c("tr", { key: unidade.id }, [
                _c("td", [_vm._v(_vm._s(unidade.id))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(unidade.descricao))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(unidade.valor))])
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("modal-unidade", { attrs: { onClose: _vm.onClose } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Unidades de medida")]),
      _vm._v(" "),
      _c("div", { staticClass: "card-tools" }, [
        _c(
          "div",
          {
            staticClass: "input-group input-group-sm",
            staticStyle: { width: "110px" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-sm bg-gradient-primary",
                attrs: {
                  type: "button",
                  "data-toggle": "modal",
                  "data-target": "#modal-unidade"
                }
              },
              [_vm._v("Cadastrar nova")]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", [_vm._v("Sigla")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }