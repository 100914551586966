<template>
    <div class="card-body">

        <div class="card-header">

            <h3 class="card-title">Empresas</h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 110px;">
                    <button type="button" @click="setEmpresa(null)" class="btn btn-block btn-sm bg-gradient-primary" data-toggle="modal" data-target="#modal-empresa">Cadastrar nova</button>
                </div>
            </div>

        </div>

        <div class="table-responsive">
            <table class="table m-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nome fantasia</th>
                        <th>Razão social</th>
                        <th>CPF/CNPJ</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="empresa in empresas" :key="empresa.id">
                        <td>{{empresa.id}}</td>
                        <td>{{empresa.nome_fantasia}}</td>
                        <td>{{empresa.razao_social}}</td>
                        <td>{{empresa.cpf_cnpj}}</td>
                        <td class="text-right">
                            <div class="dropdown">
                                <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" @click="setEmpresa(empresa.id)" data-toggle="modal" data-target="#modal-empresa">Editar</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <modal-empresa :onClose="onClose" :empresa_id="empresa_id"></modal-empresa>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            novoCadastro: 0,
            empresa_id: null
        }
    },       
    computed: {
        ...mapState({
            empresas: state => state.empresa.empresas,
        })
    },
    created() {
        this.$store.dispatch('getEmpresas')
    },
    methods: {
        onClose() {
            this.$store.dispatch('getEmpresas');
            this.empresa = null
        },
        setEmpresa(id) {
            this.empresa_id = id;
        }
    }
}
</script>