var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                attrs: {
                  label: "Nome",
                  required: true,
                  name: "nome",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                attrs: {
                  label: "Valor",
                  required: true,
                  name: "subtotal",
                  placeholder: "0,00",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                attrs: {
                  label: "Código do produto",
                  name: "codigo",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "vue-label",
                {
                  attrs: {
                    label: "Unidade*",
                    name: "unidade",
                    errors: _vm.errors
                  }
                },
                [
                  _c("vue-select-original", {
                    attrs: {
                      value: _vm.fields.unidade,
                      options: _vm.opcoesUnidades,
                      label: "label",
                      reduce: function(item) {
                        return item.value
                      }
                    },
                    on: {
                      input: function($event) {
                        _vm.fields.unidade = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "vue-label",
                {
                  attrs: {
                    label: "Origem do Produto*",
                    name: "origem",
                    errors: _vm.errors
                  }
                },
                [
                  _c("vue-select-original", {
                    attrs: {
                      value: _vm.fields.origem,
                      options: _vm.opcoesOrigemProduto,
                      label: "label",
                      reduce: function(item) {
                        return item.value
                      }
                    },
                    on: {
                      input: function($event) {
                        _vm.fields.origem = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "####.##.##",
                    expression: "'####.##.##'"
                  }
                ],
                attrs: {
                  label: "NCM",
                  name: "ncm",
                  required: true,
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "#######",
                    expression: "'#######'"
                  }
                ],
                attrs: {
                  label: "CEST",
                  name: "cest",
                  tooltip: "O parâmetro CEST deve possuir 7 dígitos",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                attrs: {
                  label: "GTIN",
                  name: "gtin",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                attrs: {
                  label: "GTIN tributável",
                  name: "gtin_tributavel",
                  errors: _vm.errors,
                  fields: _vm.fields
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("impostos", {
        attrs: { title: "Impostos", errors: _vm.errors, fields: _vm.fields }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }