const relatorios = {
    state: {
        errors: {
            data_inicio: '',
            data_final: '',
            modelo: '',
            relatorio: '',
            status: '',
            response: '',
            empresa_id: '',
        },
        filtros: {
            data_inicio: '',
            data_final: '',
            modelo: '',
            relatorio: '',
            status: '',
            empresa_id: '',
        },
        carregando: false,
    },

    getters: {
        getModelos: () => [
            { key: "nfe", value: "NF-e" },
            { key: "nfce", value: "NFCE" },
            { key: "cce", value: "CCE" },
        ],
        getRelatorios: () => [
            { key: "csv", value: "CSV" },
            { key: "xml", value: "XML" },
            { key: "danfe", value: "DANFE" },
        ],
        getStatus: () => [
            { key: "emitidas", value: "Emitidas" },
            { key: "canceladas", value: "Canceladas" },
            { key: "denegadas", value: "Denegadas" },
            { key: "inutilizadas", value: "Inutilizadas" },
        ],
    },

    mutations: {
        SET_ERRORS(state, error){
            // limpar errors
            Object.keys(state.errors).forEach(key => state.errors[key] = null);
            // manter valores padrão / alterar apenas erros efetivos
            if (error && typeof error === "object") {
                Object.entries(error).forEach(([key, value]) => state.errors[key] = value);
                console.log(state.errors);
            }
        },
        SET_CARREGANDO(state, carregando){
            state.carregando = carregando;
        }
    },

    actions: {
        async submit({ commit, state }) {
            commit("SET_ERRORS", null);
            commit("SET_CARREGANDO", true);
            axios.post('/nota-fiscal/relatorios', state.filtros).then(response => {
                if (response.errors) {
                    throw response.errors;
                }
                if (response.data.errors) {
                    throw response.data.errors;
                }
                window.open(response.data.url);
            }).catch(e => {
                if (e?.response?.data?.errors){
                    commit("SET_ERRORS", e.response.data.errors);
                } else {
                    commit("SET_ERRORS", e);
                }
            }).finally(() => {
                commit("SET_CARREGANDO", false);
            });
        }
    }
}

export default relatorios;
