import Vue from 'vue';

export const impostos = 
{
    detalhamento: {
        tipo_detalhamento: ''
    },
    exportacao: {
        tipo_exportacao: '',
    },
    // importacao: {
    //     aliquota: '',
    //     iof: '',
    //     ndoc_importacao: '',
    //     ddoc_importacao: '',
    //     uf_desembaraco: '',
    //     local_desembaraco: '',
    //     data_desembaraco: '',
    //     via_transporte: '',
    //     intermediacao: '',
    //     fabricante: '',
    //     adicao: '',
    //     seq_adicao: '',
    //     afrmm: '',
    //     cnpj_terceiro: '',
    //     uf_terceiro: '',
    //     cod_exportador: '',
    //     nfci: '',
    // },
    icms: {
        tipo_tributacao: '',
        codigo_cfop: '',
        situacao_tributaria: '',
        situacao_tributaria_icms_st: '',
        industria: '',
        aliquota_reducao: 0,
        aliquota_reducao_st: 0,
        aliquota_credito: 0,
        aliquota_mva: 0,
        aliquota_efetiva_icms_st: 0,
        aliquota_efetiva_icms: 0,
        motivo_desoneracao: '',
        aliquota_importacao: 0,
        alq_credito: '',
        alq_mva: '',
        motivo_desoneracao: '',
        aliquota_diferimento: '',
    },
    ipi: {
        situacao_tributaria: '',
        codigo_enquadramento: '',
        aliquota: '',
    },
    pis: {
        situacao_tributaria: '',
        aliquota: '',
    },
    cofins: {
        situacao_tributaria: '',
        aliquota: '',
    },
    estimativa: {
        tributos_federais: 0,
        tributos_estaduais: 0,
    },
    importacao: {
        aliquota: 0,
        iof: 0,
    }
};

export const produto = {
    id: '',
    nome: '',
    produto: '',
    quantidade: '',
    subtotal: '',
    total: '',
    codigo: '',
    unidade: '',
    origem: '',
    ncm: '',
    cest: '',
    gtin: '',
    gtin_tributavel: '',
    impostos: {...impostos},
    deleted_at: false,
    // documento de importação
    ndoc_importacao: null,
    ddoc_importacao: null,
    local_desembaraco: null,
    uf_desembaraco: null,
    data_desembaraco: null,
    via_transporte: null,
    intermediacao: null,
    adicao: null,
    seq_adicao: null,
    fabricante: null,
    afrmm: null,
    cnpj_terceiro: null,
    cod_exportador: null,
    nfci: null,

    // exportação
    drawback: null,
    reg_exportacao: null,
    nfe_exportacao: null,
    qtd_exportacao: null,
};

const defaultState = () => {
    return {
        errors: {
            operacao: {},
            cliente: {},
            produtos: {},
            transporte: {},
            pedido: {},
            impostos: {},
            carta_correcao: {}
        },
        operacao: {
            operacao: '',
            ambiente: 2,
            modelo: 1,
            serie: '',
            situacao_tributaria: '',
            codigo_cfop: '',
            valor_icms: '',
            numeracao_inicial: '',
            numeracao_final: '',
            motivo_inutilizacao: '',
            informacoes_fisco: '',
            informacoes_complementares: '',
        },
        lista_produtos: {},
        produtos: [],
        chave: 0,
        nota: {

        },
        cliente: {
            tipo_pessoa: null,
            // info estrangeiro
            id_estrangeiro: null,
            nome_estrangeiro: null,
            cod_pais: null,
            nome_pais: null,
            endereco: null,
            bairro: null,
            numero: null,
            complemento: null,
        },
        transporte: {},
        pedido: {
            'pedido': {
                presenca: '',
                modalidade_frete: '',
                frete: 0,
                desconto: 0,
                total: 0,
                despesas_acessorias: 0,
                despesas_aduaneiras: 0,
                informacoes_fisco: '',
                informacoes_complementares: '',
                observacoes_contribuinte: '',
            },
            'pagamento': { 0: {} },
            'fatura': {
                numero: '',
                valor: '',
                desconto: 0,
                valor_liquido: '',
            },
            'parcelas': { 0: {} },
            presenca: '',
            modalidade_frete: '',
            frete: 0,
            desconto: 0,
            total: 0,
            despesas_acessorias: 0,
            despesas_aduaneiras: 0,
            informacoes_fisco: '',
            informacoes_complementares: '',
            observacoes_contribuinte: '',
        },
        cartaCorrecao: {},
        total: 0,
        loading: false,
        linhaNovaSelecao: false,
        linha: false,
        modalTributos: true,
        impostos: {

        },
        notas: {},
        configuracoes: {},
        notaDevolucao: false,
        formKey: 0,
        emissao: null,
        showTabImportacao: false,
        numeracao_inicial: null,
        numeracao_final: null,
    }
}

const notaFiscal = {

    state: defaultState(),

    getters: {
        getProdutos: state => {
            return state.produtos.filter(produto => !produto.deleted_at)
        },
        getProdutoModel: () => {
            return produto;
        },
        getImpostosModel: () => {
            return impostos;
        },
        produto: state => id => {
            return state.produtos[id]
        },
        fields: state => (finalidade = null) => {
            // NF-e ajuste
            if (finalidade == 3){
                return {
                    'operacao': state.operacao,
                    'cliente': state.cliente,
                }
            }

            if (finalidade == 5) {
                return {
                    'operacao': state.operacao,
                    'carta_correcao': state.cartaCorrecao
                }
            }

            // NF-e complementar de impostos
            if (finalidade == 2 && state.operacao.nfe_complementar === "impostos"){
                return {
                    'operacao': state.operacao,
                    'cliente': state.cliente,
                    'impostos': state.impostos
                }
            }

            return {
                'operacao': state.operacao,
                'cliente': state.cliente,
                'produtos': state.produtos,
                'transporte': state.transporte,
                'pedido': state.pedido,
                'impostos': state.impostos
            }
        }
    },

    mutations: {
        SET_NOTA_FISCAL(state, nota) {
            // state.operacao = nota.operacao
            state.cliente = nota.cliente
            state.produtos = nota.produtos
            state.pedido = nota.pedido
            state.transporte = nota.transporte
            state.impostos = nota.impostos

            state.notaDevolucao = true
        },
        SET_PRODUTOS(state, produtos) {
            state.produtos = produtos;
        },
        REMOVE_PRODUTO(state, chave) {
            Vue.delete(state.produtos, chave)
        },
        SET_PRODUTO_BY_ID(state, payload) {
            const { id } = payload;
            state.produtos = state.produtos.map(_produto => _produto.id === id ? payload : _produto);
        },
        SET_LISTA_PRODUTOS(state, lista_produtos) {
            state.lista_produtos = lista_produtos
        },
        SET_LOADING(state, loading) {
            state.loading = loading
        },
        RESET_STATE: function (state) {
            Object.assign(state, defaultState())
        },
        SET_NOTAS_FISCAIS(state, notas) {
            state.notas = notas
        },
        SET_FORM_KEY(state) {
            state.formKey++
        },
        SET_TOTAL(state, total){
            state.pedido.total = total;
            state.total = total;
        },
        SET_TRIBUTACAO_PRODUTOS(state, tributacao){
            if (tributacao === "simples" || tributacao === "normal") {
                state.produtos = state.produtos.map(_produto => {
                    _produto.impostos.icms.tipo_tributacao = tributacao;
                    return _produto;
                });
                if (state.cliente){
                    state.cliente.tipo_tributacao
                }
            }
        },
        SET_ERRORS_FORM(state, errors) {
            let temProdutos = false
            let temFormasPagamento = false
            Object.entries(errors).forEach(([key, value]) => {
                let keySplit = key.split(".")
                if (keySplit[0] == 'pedido') {
                    if (keySplit[1] == 'modalidade_frete') {
                        Vue.set(state.errors['transporte'], keySplit[1], value)
                    } else if (keySplit[1] == 'presenca') {
                        Vue.set(state.errors[keySplit[0]], keySplit[1], value)
                    } else if (keySplit[1] == 'pagamento') {
                        if (!temFormasPagamento) {
                            let content = {}
                            content[keySplit[1]] = {}
                            content[keySplit[1]][keySplit[2]] = {}
                            Vue.set(content[keySplit[1]][keySplit[2]], keySplit[3], value)
                            state.errors[keySplit[0]] = content
                            temFormasPagamento = true
                        } else {
                            if (!state.errors[keySplit[0]][keySplit[1]].hasOwnProperty(keySplit[2])) {
                                state.errors[keySplit[0]][keySplit[1]][keySplit[2]] = {}
                            }
                            Vue.set(state.errors[keySplit[0]][keySplit[1]][keySplit[2]], keySplit[3], value)
                        }
                    }
                } else if (keySplit[0] == 'produtos') {
                    if (!temProdutos) {
                        let content = {}
                        content[keySplit[2]] = value
                        Vue.set(state.errors[keySplit[0]], keySplit[1], content)
                        temProdutos = true
                    } else {
                        Vue.set(state.errors[keySplit[0]][keySplit[1]], keySplit[2], value)
                    }
                } else {
                    Vue.set(state.errors[keySplit[0]], keySplit[1], value)
                }
            });
        },
        LIMPA_ERRORS_FORM(state) {
            state.errors = defaultState().errors
        },
        SET_EMISSAO(state, emissao) {
            state.emissao = emissao
        },
        SET_TAB_IMPORTACAO(state, showTabImportacao) {
            state.showTabImportacao = showTabImportacao
        },
        SET_TIPO_PESSOA(state, tipoPessoa){
            state.cliente.tipo_pessoa = tipoPessoa;
        }
    },
    actions: {
        async getNotasFiscais({ commit }, page = 1) {
            commit('SET_LOADING', true)
            const response = await axios.get('nota-fiscal/notas?page=' + page)
            commit('SET_NOTAS_FISCAIS', response.data)
            commit('SET_LOADING', false)
        },
        async fetchOpcoesSelectProduto({ commit }) {
            commit('SET_LOADING', true)
            const response = await axios.get('/produtos?paginate=0&select=1')
            commit('SET_LISTA_PRODUTOS', response.data.data)
            commit('SET_LOADING', false)
        },
        async fetchProduto({ commit, state }, id) {
            if (id === null){
                throw Error();
            }
            commit('SET_LOADING', true);
            return axios.get(`/produto/${id}`)
                .then(response => response.data.data)
                .then(_produto => {
                    if (state.cliente && state.cliente.tipo_tributacao){
                        _produto.impostos.icms.tipo_tributacao = state.cliente.tipo_tributacao;
                    }
                    return _produto;
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                });
        },
        resetState({ commit }) {
            commit('RESET_STATE')
            commit('SET_FORM_KEY')
        },
        calcularTotalPedido({ commit, state }) {
            const { desconto, despesas_acessorias, despesas_aduaneiras, frete } = state.pedido;
            let total = 0;

            const total_produtos = state.produtos.reduce((acc, atual) => {
                const total_item = atual.quantidade * atual.subtotal;
                return acc += total_item || 0;
            }, 0);

            total += total_produtos;
            total -= parseFloat(desconto) || 0;
            total += parseFloat(despesas_acessorias) || 0;
            total += parseFloat(despesas_aduaneiras) || 0;
            total += parseFloat(frete) || 0;

            if (total < 0){
                total = 0;
            }
            commit('SET_TOTAL', total);
        },
        calcularTotalItem({ commit, dispatch }, data) {
            if (Number(data.quantidade) && Number(data.subtotal)) {
                data.total = data.quantidade * data.subtotal;
            }
            commit("SET_PRODUTO_BY_ID", data);
            dispatch("calcularTotalPedido");
        },
    }

}

export default notaFiscal;
