var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { attrs: { for: _vm.name } }, [
          _vm._v(_vm._s(_vm.required ? _vm.label + "*" : _vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.tooltip
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip:top",
              value: _vm.tooltip,
              expression: "tooltip",
              arg: "top"
            }
          ],
          staticClass: "fa fa-question-circle"
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      {
        staticClass: "form-control",
        class: [
          { "border-danger": _vm.errors && _vm.errors[_vm.name] },
          _vm.classes
        ],
        attrs: {
          name: _vm.id ? _vm.name + "_" + _vm.id : _vm.name,
          required: _vm.required && _vm.required != "vuelidate",
          disabled: _vm.loading
        },
        on: { change: _vm.change }
      },
      [
        _vm.select_label
          ? _c("option", { attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm.select_label))
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.fields[_vm.name] && !_vm.select_label
          ? _c("option", { attrs: { value: "" } }, [_vm._v("Selecione")])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.options, function(option, key) {
          return _c(
            "option",
            {
              key: key,
              domProps: {
                value: option["id"] ? option["id"] : key,
                selected:
                  (option["id"] ? option["id"] : key) == _vm.fields[_vm.name] ||
                  (!_vm.fields[_vm.name] && option["padrao"])
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.show_key ? key + " - " : "") +
                  _vm._s(_vm.text_key ? option[_vm.text_key] : option) +
                  "\n        "
              )
            ]
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _vm.errors && _vm.errors[_vm.name]
      ? _c("div", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errors[_vm.name][0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }