<template>
  <div>
    <label v-if="label" :for="name">{{ required ? label + "*" : label }}</label>
    <i v-if="tooltip" class="fa fa-question-circle" v-tooltip:top="tooltip"></i>
    <input
      type="text"
      class="form-control"
      :data-a-dec="decimalSeparator"
      :data-a-sep="thousandSeparator"
      :data-m-dec="decimalPlaces"
      :readonly="readonly"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      @blur="$emit('input', rawValue)"
    />
    <div v-if="errors && errors[name]" class="text-danger">
      {{ errors[name][0] }}
    </div>
  </div>
</template>

<script>
import AutoNumeric from "autonumeric";
export default {
  props: {
    value: [Number, String],
    decimalSeparator: {
      type: String,
      required: false,
      default: ",",
    },
    thousandSeparator: {
      type: String,
      required: false,
      default: ".",
    },
    decimalPlaces: {
      type: Number,
      required: false,
      default: 2,
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    errors: {
      type: Object,
    },
    classes: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    onchange: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => ({
    autoNumericInput: null,
    raw: null,
  }),
  computed: {
    domInput() {
      return this.$el.querySelector("input");
    },
    minimumValue(){
      // retorna o valor default - 10 trilhões ou 0 como mínimo
      return this.allowNegative ? -10000000000000 : 0;
    },
    rawValue(){
      if (this.autoNumericInput){
        return this.autoNumericInput.get();
      }
      return "";
    }
  },
  methods: {
    formatNumericInput(valor){
      if (Number(valor)){
          return Number(valor);
      }
      return parseFloat(valor.replace('.', '').replace(',', '.'));
  },
  },
  mounted() {
    const vm = this;
    const valor = vm.value === undefined || isNaN(vm.value) ? null : vm.value;

    // utilizar padrão brasileiro, sem símbolo de moeda
    // customizar com props
    const options = {
      ...AutoNumeric.getPredefinedOptions().Brazilian,
      currencySymbol: '',
      decimalSeparator: vm.decimalSeparator,
      decimalPlaces: vm.decimalPlaces,
      thousandSeparator: vm.thousandSeparator,
      minimumValue: this.minimumValue,
    };

    vm.autoNumericInput = new AutoNumeric(
      vm.domInput,
      valor,
      options,
    );
  },
  watch: {
    value: function (value) {
      if (this.autoNumericInput){
        this.autoNumericInput.set(
          value === undefined || isNaN(value) ? null : value
        );
      }
    },
  },
};
</script>

<style scoped>
</style>
