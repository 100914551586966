var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-modal", {
    attrs: {
      title: !_vm.produto.id ? "Novo produto" : "Editar produto",
      modal_class: "modal-xl"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _vm.produto.id && _vm.loading && _vm.loaded
              ? _c("vue-loading", { attrs: { texto: "Carregando dados..." } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.loaded
              ? _c("vue-loading", { attrs: { texto: "Enviando..." } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: (!_vm.produto.id || !_vm.loading) && _vm.loaded,
                    expression: "(!produto.id || !loading) && loaded"
                  }
                ],
                attrs: { id: "form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c("form-produto", {
                  attrs: { errors: _vm.errors, fields: _vm.produto }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.recarregar()
                  }
                }
              },
              [_vm._v("Recarregar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("Fechar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", form: "form" }
              },
              [_vm._v("Salvar cadastro")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }