var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-modal", {
    ref: "formContainer",
    attrs: {
      title: "Cadastrar unidade",
      modal_class: "modal-md",
      id: _vm.modalId
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "form",
              {
                attrs: { id: _vm.formId },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("vue-input", {
                          attrs: {
                            label: "Nome",
                            required: true,
                            name: "nome",
                            type: "text",
                            errors: _vm.errors,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("vue-input", {
                          attrs: {
                            label: "Sigla",
                            required: true,
                            name: "sigla",
                            type: "text",
                            errors: _vm.errors,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("Fechar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", form: _vm.formId }
              },
              [_vm._v("Salvar")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }