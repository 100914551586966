<template>
    <div class="col-12">
        <h5 v-if="title" class="mt-2 text-bold">{{title}}</h5>
        <vue-tab-panel :errors="errors" :fields="fields.impostos" :show="true" :tabs="tabs()" @abaSelecionada="abaSelecionada = $event">
            <vue-tab-pane id="tab-1" :active="abaSelecionada === 'icms'">
                <form-icms :errors="errors" :fields="fields.impostos.icms"/>
            </vue-tab-pane>
            <vue-tab-pane id="tab-2" :active="abaSelecionada === 'ipi'">
                <form-ipi :errors="errors" :fields="fields.impostos.ipi" />
            </vue-tab-pane>
            <vue-tab-pane id="tab-3" :active="abaSelecionada === 'pis'">
                <form-pis :errors="errors" :fields="fields.impostos.pis" />
            </vue-tab-pane>
            <vue-tab-pane id="tab-4" :active="abaSelecionada === 'cofins'">
                <form-cofins :errors="errors" :fields="fields.impostos.cofins" />
            </vue-tab-pane>
            <vue-tab-pane id="tab-5" :active="abaSelecionada === 'estimativa'">
                <form-estimativa :errors="errors" :fields="fields.impostos.estimativa" />
            </vue-tab-pane>
            <vue-tab-pane id="tab-6" :active="abaSelecionada === 'importacao'">
                <form-importacao :errors="errors" :fields="fields" />
            </vue-tab-pane>
        </vue-tab-panel>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        props: ['title', 'fields', 'errors'],
        data() {
            return {
                abaSelecionada: 'icms',
            }
        },
        methods: {
            tabs() {
                return {
                    'icms' : {
                        'label' : 'ICMS',
                        'show' : true,
                        'active' : true
                    },
                    'ipi' : {
                        'label' : 'IPI',
                        'show' : true
                    },
                    'pis' : {
                        'label' : 'PIS',
                        'show' : true
                    },
                    'cofins' : {
                        'label' : 'COFINS',
                        'show' : true
                    },
                    'estimativa' : {
                        'label' : 'Estimativa de Tributos',
                        'show' : true
                    },
                    'importacao' : {
                        'label' : 'Importação',
                        'show' : this.exibirImportacao,
                    },
                    // 'exportacao' : {
                    //     'label' : 'Exportação',
                    //     'show' : false,
                    // },
                    // 'detalhamento' : {
                    //     'label' : 'Detalhamento',
                    //     'show' : false
                    //},
                }
            }
        },
        computed: {
            ...mapState({
                emissao: state => state.nota.emissao,
            }),
            exibirImportacao(){
                // if (!this.emissao) {
                //     return true;
                // }
                return this.emissao === "importacao";
            }
        }
    }
</script>