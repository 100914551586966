var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        key: _vm.formKey,
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("vue-retorno-api", { attrs: { retorno: _vm.retorno } }),
        _vm._v(" "),
        _vm._l(_vm.errors, function(error, name) {
          return _c("div", { key: name + "_" + _vm.formKey }, [
            !_vm.isEmptyObject(error)
              ? _c("p", { staticClass: "alert alert-default-danger" }, [
                  _vm._v(
                    "Existem dados obrigatórios não informados na aba " +
                      _vm._s(_vm.abaErro(name))
                  )
                ])
              : _vm._e()
          ])
        }),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "Finalidade",
                      required: true,
                      name: "finalidade",
                      options: _vm.opcoesFinalidade,
                      onchange: _vm.selectFinalidade,
                      errors: _vm.errorsOperacao,
                      objeto: "operacao",
                      fields: _vm.operacao
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.finalidade != 5 && _vm.finalidade != 6
                ? _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "Tipo de emissão",
                          required: true,
                          name: "operacao",
                          options: _vm.opcoesOperacao,
                          onchange: _vm.changeForm,
                          errors: _vm.errorsOperacao,
                          objeto: "operacao",
                          fields: _vm.operacao
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.finalidade != 5 && _vm.finalidade != 6
                ? _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("vue-input", {
                        attrs: {
                          label: "Natureza da operação",
                          required: true,
                          name: "natureza_operacao",
                          type: "text",
                          errors: _vm.errorsOperacao,
                          objeto: "operacao",
                          fields: _vm.operacao
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.finalidade == 2
              ? _c("div", { staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "Complementar",
                          required: true,
                          name: "nfe_complementar",
                          options: _vm.opcoesTipoComplementar,
                          onchange: _vm.selectTipoComplementar,
                          errors: _vm.errorsOperacao,
                          objeto: "operacao",
                          fields: _vm.operacao
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-8" },
                    [
                      _c("vue-input", {
                        attrs: {
                          label:
                            "Chave de acesso da NF-e emitida anteriormente",
                          required: true,
                          name: "nfe_referenciada",
                          type: "text",
                          errors: _vm.errorsOperacao,
                          objeto: "operacao",
                          fields: _vm.operacao
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.finalidade == 4
              ? _c("div", { staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("vue-input", {
                        attrs: {
                          label:
                            "Chave de acesso da NF-e emitida anteriormente",
                          required: true,
                          name: "nfe_referenciada",
                          type: "text",
                          errors: _vm.errorsOperacao,
                          objeto: "operacao",
                          fields: _vm.operacao
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "vue-tab-panel",
                {
                  attrs: {
                    ul_class: "mb-3",
                    errors: _vm.errors,
                    fields: _vm.fields,
                    tabs: _vm.showTabs()
                  },
                  on: {
                    abaSelecionada: function($event) {
                      _vm.abaSelecionada = $event
                    }
                  }
                },
                [
                  [
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "1",
                          active: _vm.abaSelecionada === "cliente-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["cliente-emitir"].show === true
                          ? _c("form-cliente-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.cliente
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "2",
                          active: _vm.abaSelecionada === "produto-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["produto-emitir"].show === true
                          ? _c("form-produto-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.produto
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "3",
                          active: _vm.abaSelecionada === "transporte-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["transporte-emitir"].show === true
                          ? _c("form-transporte-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.transporte
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "4",
                          active: _vm.abaSelecionada === "pedido-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["pedido-emitir"].show === true
                          ? _c("form-pedido-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.pedido
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "5",
                          active: _vm.abaSelecionada === "impostos-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["impostos-emitir"].show === true
                          ? _c("form-impostos-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.impostos
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "6",
                          active: _vm.abaSelecionada === "ajuste-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["ajuste-emitir"].show === true
                          ? _c("form-ajuste-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.ajuste
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "7",
                          active: _vm.abaSelecionada === "carta-correcao-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["carta-correcao-emitir"].show === true
                          ? _c("form-carta-correcao-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.cartaCorrecao
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-tab-pane",
                      {
                        attrs: {
                          id: "8",
                          active:
                            _vm.abaSelecionada === "inutilizar-numeracao-emitir"
                        }
                      },
                      [
                        _vm.showTabs()["inutilizar-numeracao-emitir"].show ===
                        true
                          ? _c("form-inutilizar-numeracao-emitir", {
                              attrs: {
                                errors: _vm.errors,
                                fields: _vm.fields.inutilizarNumeracao
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c("vue-radio", {
                attrs: {
                  fields: _vm.operacao,
                  errors: _vm.errors,
                  name: "ambiente",
                  inline: true,
                  options: JSON.stringify({ 1: "Produção", 2: "Homologação" }),
                  onchange: function() {}
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary float-right",
                  attrs: { type: "submit" }
                },
                [_vm._v("Enviar")]
              )
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }