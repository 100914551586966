var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.retorno != undefined && _vm.retorno != null
    ? _c("div", { class: _vm.classRetorno }, [
        _c("div", { staticClass: "text-bold text-capitalize" }, [
          _vm._v("\n        " + _vm._s(_vm.retorno.status) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.retorno.error
          ? _c("div", [
              _vm._v("\n        " + _vm._s(_vm.retorno.error) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.retorno.log != undefined && _vm.retorno.log != null
          ? _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.retorno.log.aProt
                      ? _vm.retorno.log.aProt[0].xMotivo
                      : _vm.retorno.log.xMotivo
                  ) +
                  "\n    "
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }