<template>
  <div class="table-editable">
    <table class="table" :key="'table_vlista_' + tableKey">
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            :width="column.width"
          >
            {{ column.label }}
          </th>
          <th width="6%" class="text-right">
            <a
              class="pull-right fa fa-plus-circle fa-2x text-success"
              @click="addLine"
              style="right: 7px"
            ></a>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot name='body' :removeLine="removeLine" :openModal="openModal"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
    dataModel: {
      type: Function,
      required: true,
    },
    // Indica se a tabela inicia com uma linha vazia
    startLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      tableKey: 1,
    };
  },
  mounted() {
    const model = {...this.dataModel()}
    this.tableDatas = this.startLine ? [model] : [];
  },
  methods: {
    openModal(data = null) {
      this.$emit("addModalOpen", data);
    },
    addLine() {
      const model = {...this.dataModel()};
      this.itens.push(model);
    },
    removeLine(item) {
      if (!confirm(`Deseja mesmo excluir essa linha?`)) {
        return;
      }
      item.deleted_at = true;
    },
  },
};
</script>

<style scoped>
.td_buttons {
  display: inline-flex;
}
.btn_edit_modal {
  margin-right: 5px;
  margin-top: 2px;
}
a {
  cursor: pointer;
}
</style>
