var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm._l(_vm.line_keys, function(content, index) {
          return _c(_vm.component, {
            key: index,
            tag: "component",
            attrs: {
              id: _vm.component + "_append_" + index,
              removeRow: _vm.removeRow,
              fields: _vm.fields[index],
              errors: _vm.errors
            }
          })
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-primary float-right mt-2 mr-2",
            attrs: { type: "button", disabled: _vm.loading },
            on: { click: _vm.addRow }
          },
          [
            _vm.loading
              ? _c("span", {
                  staticClass: "spinner-border spinner-border-sm",
                  attrs: { role: "status", "aria-hidden": "true" }
                })
              : _vm._e(),
            _vm._v(
              "\n            " +
                _vm._s(_vm.button_text ? _vm.button_text : "Adicionar") +
                "\n        "
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }