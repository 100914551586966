var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            !_vm.show_consumidor_final
              ? _c("vue-input", {
                  attrs: {
                    label: "Nome completo*",
                    name: "nome_completo",
                    type: "text",
                    errors: _vm.errors,
                    fields: _vm.fields,
                    loading: _vm.loading
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.show_consumidor_final
              ? _c("vue-search-input", {
                  attrs: {
                    label: "Nome completo*",
                    name: "nome_completo",
                    type: "text",
                    errors: _vm.errors,
                    fields: _vm.fields,
                    loading: _vm.loading,
                    searchFields: _vm.clientes,
                    searchKey: "Nome/Razão social",
                    onSelect: _vm.getCliente
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "###.###.###-##",
                  expression: "'###.###.###-##'"
                }
              ],
              attrs: {
                label: "CPF*",
                name: "cpf_cnpj",
                type: "text",
                placeholder: "000.000.000-00",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { class: _vm.show_consumidor_final ? "col-6" : "col-12" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Tributação",
                name: "tipo_tributacao",
                options: _vm.tributacaoIcms,
                text_key: "nome",
                errors: _vm.errors,
                fields: _vm.fields,
                onchange: _vm.selectTributacaoIcms
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.show_consumidor_final
        ? _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "Consumidor Final (opcional)",
                    name: "consumidor_final",
                    options: _vm.opcoesConsumidorFinal,
                    select_label: "---",
                    errors: _vm.errors,
                    fields: _vm.fields,
                    loading: _vm.loading
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "#####-###",
                  expression: "'#####-###'"
                }
              ],
              attrs: {
                label: "CEP*",
                name: "cep",
                type: "text",
                placeholder: "00000-000",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Endereço*",
                name: "endereco",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Número*",
                name: "numero",
                type: "number",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Complemento",
                name: "complemento",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Bairro*",
                name: "bairro",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Cidade*",
                name: "cidade",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Estado*",
                select_label: "Selecione",
                name: "uf",
                options: _vm.estados,
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "E-mail*",
                name: "email",
                type: "email",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: ["(##) ####-####", "(##) #####-####"],
                  expression: "['(##) ####-####', '(##) #####-####']"
                }
              ],
              attrs: {
                label: "Telefone*",
                name: "telefone",
                type: "tel",
                placeholder: "(00) 00000-0000",
                errors: _vm.errors,
                fields: _vm.fields,
                loading: _vm.loading
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 align-self-end" },
        [
          _vm.showSalvarCliente && _vm.show_consumidor_final
            ? _c("vue-checkbox", {
                attrs: {
                  label: "Salvar cliente",
                  name: "salvar_cliente",
                  value: "2",
                  fields: _vm.fields
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }