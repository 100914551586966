<template>
  <div class="px-2">
    <vue-lista
      box_title="Produtos"
      :columns="[
        {
          label: 'Produto',
          width: '35%',
        },
        {
          label: 'CFOP',
          width: '10%',
        },
        {
          label: 'Un.',
          width: '10%',
        },
        {
          label: 'Qtd.',
          width: '15%',
        },
        {
          label: 'Valor Un. R$',
          width: '15%',
        },
        {
          label: 'Total R$',
          width: '15%',
        },
      ]"
      :start-line="true"
      :dataModel="this.getProdutoModel"
      :itens="produtos"
    >
      <template v-slot:body="{ openModal, removeLine }">
        <tr
          v-for="(data, index) in produtos"
          v-if="data.deleted_at === false"
          :key="index"
        >
          <td>
            <vue-select-original
              v-model="data.produto"
              :options="Array.from(listaProdutos)"
              label="text"
              :reduce="(item) => item.id"
              @input="onSelectProduto($event, index)"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="data.impostos.icms.codigo_cfop"
            />
          </td>
          <td>
            <input class="form-control" type="text" v-model="data.unidade" />
          </td>
          <td>
            <vue-autonumeric
              type="text"
              v-model="data.quantidade"
              @input="calcularTotalItem(data)"
            />
          </td>
          <td>
            <vue-autonumeric
              name="subtotal"
              type="text"
              :decimalPlaces="4"
              v-model="data.subtotal"
              @input="calcularTotalItem(data)"
            />
          </td>
          <td>
            <vue-autonumeric
              name="total"
              type="text"
              v-model="data.total"
              :disabled="true"
            />
          </td>
          <td class="text-right td_buttons">
            <a
              data-toggle="modal"
              data-target="#myModal"
              class="btn_edit_modal fa fa-pencil-alt fa-2x text-primary"
              aria-hidden="true"
              @click="
                produto = data;
                openModal(data);
              "
              v-if="data.produto"
            ></a>
            <a
              class="fa fa-trash fa-2x text-danger"
              aria-hidden="true"
              @click="removeLine(data)"
            ></a>
          </td>
        </tr>
      </template>
    </vue-lista>
    <modal-produto
      v-if="produto"
      :produto="produto"
      :onClose="onClose"
    ></modal-produto>
    <div class="text-right">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        data-toggle="modal"
        data-target="#myModal"
        @click="cadastrarProduto()"
      >
        Cadastrar novo
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { produto, impostos } from "../../../../modules/nota-fiscal/notaFiscal";
export default {
  data() {
    return {
      produto: {},
    };
  },
  computed: {
    ...mapState({
      listaProdutos: (state) => state.nota.lista_produtos,
      produtos: (state) => state.nota.produtos,
      produtoDefaults: (state) => state.produto,
    }),
  },
  methods: {
    ...mapMutations(["SET_PRODUTOS"]),
    ...mapGetters(["getProdutoModel"]),
    ...mapActions([
      "fetchProduto",
      "fetchOpcoesSelectProduto",
      "calcularTotalItem",
    ]),
    onClose() {
      this.fetchOpcoesSelectProduto("getOpcoesSelectProduto");
    },
    cadastrarProduto(){
      this.produto = { ...this.getProdutoModel() };
      this.$emit("addModalOpen", this.produto);
    },
    onSelectProduto(id, row) {
      this.fetchProduto(id)
        .then((produto) => {
          const quantidade = this.produtos[row].quantidade;
          Object.entries(produto).forEach(([chave, valor]) => {
            this.produtos[row][chave] = valor;
          });
          if (Number(quantidade) && Number(this.produtos[row].subtotal)) {
            this.produtos[row].quantidade = quantidade;
            this.produtos[row].total = quantidade * this.produtos[row].subtotal;
          }
          this.produtos[row].produto = id;
        })
        .catch(() => {
          this.produtos[row].produto = null;
        });
    },
  },
  beforeMount(){
    this.produto = { ...this.getProdutoModel() };
  },
  mounted() {
    const model = { ...this.getProdutoModel() };
    this.SET_PRODUTOS([model]);
    this.fetchOpcoesSelectProduto("getOpcoesSelectProduto");
  },
};
</script>
<style scoped>
.td_buttons {
  display: inline-flex;
}
.btn_edit_modal {
  margin-right: 5px;
  margin-top: 2px;
}
a {
  cursor: pointer;
}
</style>