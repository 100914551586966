var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        refs: _vm.id ? _vm.id : "myModal",
        tabindex: "-1",
        role: "dialog",
        id: _vm.id ? _vm.id : "myModal"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          class: _vm.modal_class,
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm.header != false
              ? _c("div", { staticClass: "modal-header" }, [
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }