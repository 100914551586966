<template>
    <vue-modal title="Cadastrar unidade" modal_class="modal-lg" :id="modalId" ref="formContainer">

        <template v-slot:body>

            <form @submit.prevent="submit" :id="formId">

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <vue-input label="Nome fantasia" :required="true" name="nome_fantasia" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <vue-input label="Razão social" :required="true" name="razao_social" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <vue-input label="CPF/CNPJ" :required="true" name="cpf_cnpj" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <vue-input label="Access token" :required="true" name="access_token" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <vue-input label="Access token secret" :required="true" name="access_token_secret" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <vue-input label="Consumer key" :required="true" name="consumer_key" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <vue-input label="Consumer secret" :required="true" name="consumer_secret" type="text" :errors="errors" :fields="fields"></vue-input>
                        </div>
                    </div>
                </div>

            </form>

        </template>

        <template v-slot:footer>
            <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
            <button type="submit" :form="formId" class="btn btn-primary">Salvar</button>
        </template>

    </vue-modal>
</template>

<script>
    export default {
        props: ['onClose', 'empresa_id'],
        data() {
            return {
                modalId: 'modal-empresa',
                formId: 'form-empresa',

                fields: this.defaultFields(),
                errors: {},
            }
        },
        watch: {
            empresa_id(newVal, oldVal) {
                if(newVal != oldVal && newVal != undefined && newVal != null) {
                    this.getEmpresa(newVal)
                } else if(newVal == null) {
                    this.fields = this.defaultFields()
                }
            }
        },
        methods: {
            defaultFields() {
                return {
                    access_token: "",
                    access_token_secret: "",
                    consumer_key: "",
                    consumer_secret: "",
                    cpf_cnpj: "",
                    nome_fantasia: "",
                    razao_social: ""
                }
            },
            submit() {
                let loader = this.$loading.show();
                this.errors = {};
                axios.post('empresa/cadastrar', this.fields).then(response => {
                    this.fields = {}; //Clear input fields.
                    this.loaded = true;
                    this.success = true;
                    this.onClose()
                    $('#'+this.modalId).modal('hide')
                    loader.hide();
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                    loader.hide();
                });
            },
            getEmpresa(empresaId) {
                axios.get('empresa/'+empresaId).then(response => {
                    this.fields = response.data
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    console.log(error)
                });
            },
        }
    }
</script>