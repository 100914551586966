<template>
    <v-select-nuntius
        :label="label"
        :filterable="false"
        :options="options"
        @search="onSearch"
        @input="send"
        v-model="selected"
        :disabled="disabled"
        :maxHeight="maxHeight"
        :clearable="clearable"
        :required="required"
    >
        <template slot="no-options">
            {{ noOptions}}
        </template>
    </v-select-nuntius>
</template>

<script>
    /**
     * Para usar as opções default, use como no exemplo abaixo:
     *
     * <v-select-ajax
     * url="/cadastros/estoque/armazem/enum"
     * v-model="armazem_id"
     * :default-options="[{value: 1, text: 'Armazém teste'}]"
     * ></v-select-ajax>
     * Desta maneira, o sistema já vai saber que a opção foi selecionada, é útil para quando está em modo de
     * visualização/edição
     */
    import VSelectNuntius from './VSelectNuntius';

    export default {
        components: {
            VSelectNuntius
        },
        props: {
            url: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: false,
                default: 'text'
            },
            noOptions: {
                type: String,
                required: false,
                default: 'Clique e comece a digitar...'
            },
            defaultOptions: {
                type: Array,
                required: false
            },
            /**
             * Espera o value de uma opção
             * Quando esse parâmetro é passado junto com autoload = true
             * Seleciona a opção equivalente a aquele valor
             * Ex:
             * <v-select-ajax
                url="/cadastros/faturamento/parametrizacao_fiscal/enum"
                v-model="precos.parametrizacao_fiscal_id"
                :option-default-value="precos.parametrizacao_fiscal_id"
                :disabled="false"
                :autoload="true"
                ></v-select-ajax>
             */
            optionDefaultValue: {
                type: [String, Number],
                required: false
            },
            /**
             * O mesmo que o optionDefaultValue porém passando o valor do text
             */
            optionDefaultText: {
                type: [String, Number],
                required: false
            },
            autoload: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            maxHeight: {
                type: String,
                default: '200px'
            },
            // Indica a presença do botão de clear
            clearable: {
                type: Boolean,
                required: false,
                default: true
            }
            // value: Number
        },
        data: function () {
            return {
                options: [],
                autload: false,
                selected: null
            }
        },
        methods: {
            onSearch(search, loading) {
                loading(true);
                this.search(loading, search, this);
            },
            search: _.debounce(function (loading, search, vm) {
                window.axios.get(this.url, {params: {q: search}})
                    .then(({data}) => {
                        vm.options = data.data;
                        loading(false);
                    })
            }, 350),
            send() {
                let value = this.selected ? this.selected.value : null;
                // emite o valor de input
                this.$emit('input', value);
                //emite o objeto selecionado
                this.$emit('optionSelected',this.selected);
            }
        },
        mounted() {
            if (this.defaultOptions) {
                this.options = this.defaultOptions;
                this.selected = _.first(this.options);
            }
            if (this.autoload) {
                window.axios.get(this.url)
                    .then(({data}) => {
                        this.options= data.data;
                        if (this.options.length && this.optionDefaultValue) {
                            let optionValue = this.optionDefaultValue;
                            let option = this.options.find(a => {return a.value == optionValue});
                            if(option) {
                                this.selected = option;
                            }
                        }
                        if (this.options.length && this.optionDefaultText) {
                            let optionText = this.optionDefaultText;
                            let option = this.options.find(a => {return a.text == optionText});
                            if(option) {
                                this.selected = option;
                            }
                        }
                    })
            }
        },
        watch: {
            defaultOptions: {
                handler: function(val){
                    if (!this.autoload) {
                        this.options = val;
                    }
                    this.selected = _.first(val);

                },
                deep: true
            }

        }
    }
</script>

<style scoped>

</style>