var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-10" }, [
        _c(
          "div",
          { staticClass: "tab-content", attrs: { id: "vert-tabs-tabContent" } },
          [
            _c(
              "div",
              {
                staticClass: "tab-pane text-left fade show active",
                attrs: {
                  id: "vert-tabs-unidades",
                  role: "tabpanel",
                  "aria-labelledby": "vert-tabs-unidades-tab"
                }
              },
              [_c("form-configuracao-unidades")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: {
                  id: "vert-tabs-empresas",
                  role: "tabpanel",
                  "aria-labelledby": "vert-tabs-empresas-tab"
                }
              },
              [_c("form-configuracao-empresas")],
              1
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c(
        "div",
        {
          staticClass: "nav flex-column nav-tabs h-100",
          attrs: {
            id: "vert-tabs-tab",
            role: "tablist",
            "aria-orientation": "vertical"
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "vert-tabs-unidades-tab",
                "data-toggle": "pill",
                href: "#vert-tabs-unidades",
                role: "tab",
                "aria-controls": "vert-tabs-unidades",
                "aria-selected": "true"
              }
            },
            [_vm._v("Unidades")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "vert-tabs-empresas-tab",
                "data-toggle": "pill",
                href: "#vert-tabs-empresas",
                role: "tab",
                "aria-controls": "vert-tabs-empresas",
                "aria-selected": "false"
              }
            },
            [_vm._v("Empresas")]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary float-right",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }