<template>
    <div class="card">

        <div class="card-header">

            <h3 class="card-title">Produtos</h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                    <button type="button" class="btn btn-block bg-gradient-primary" data-toggle="modal" data-target="#myModal" @click="setProduto(null)">Cadastrar novo</button>
                </div>
            </div>
        </div>
        <vue-table
            :key="novoCadastro"
            :rota="$routes.route('produtos')"
            :titulos="['#', 'Nome', 'Código', 'NCM', 'Valor']"
            :editar="setProduto"
        ></vue-table>

        <modal-produto :produto="produto" :onClose="onClose"></modal-produto>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {
        data() {
            return {
                produto: {...this.getProdutoModel()},
                novoCadastro: 0,
            }
        },
        methods: {
            ...mapActions(["fetchProduto"]),
            ...mapGetters(["getProdutoModel"]),
            setProduto(produto_id) {
                if (produto_id === null){
                    this.produto = {...this.getProdutoModel()};
                } else {
                    this.fetchProduto(produto_id).then(produto => {
                        Object.entries(produto).forEach(([chave, valor]) => this.produto[chave] = valor);
                    });
                }
            },
            onClose() {
                this.novoCadastro++
            }
        }
    }
</script>

<style scoped>
    .card-title {
        font-size: 1.5rem;
    }
</style>
