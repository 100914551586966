var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Chave de acesso da NF-e emitida anteriormente",
                name: "chave",
                type: "text",
                required: "vuelidate",
                errors: _vm.errors,
                fields: _vm.cartaCorrecao
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-textarea", {
              attrs: {
                label: "Correção",
                name: "correcao",
                rows: "3",
                type: "text",
                required: "vuelidate",
                errors: _vm.errors,
                fields: _vm.cartaCorrecao
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }