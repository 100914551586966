<template>
    <div class="col-12">
        <div class="row">
            <!-- <div class="col-3">
                <div class="form-group">
                    <vue-input label="Data de Entrada ou Saída" name="data_entrada_saida" type="date" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div> -->
            <div class="col">
                <div class="form-group">
                    <vue-label label="Situação tributária*" name="situacao_tributaria" :errors="errors">
                        <vue-select-original
                            :value="fields.situacao_tributaria"
                            @input="fields.situacao_tributaria = $event"
                            :options="opcoesSituacaoTributariaSelecionada"
                            label="label"
                            :required="'vuelidate'"
                            :reduce="(item) => item.value"
                        />
                    </vue-label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <vue-input label="Código CFOP de ajuste" name="codigo_cfop" v-mask="'#.###'" placeholder="_.___" type="text" :required="'vuelidate'" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <vue-input label="Valor do ICMS a ser ajustado" name="valor_icms" type="text" :required="'vuelidate'" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <vue-textarea label="Informações ao fisco" name="informacoes_fisco" rows="3" type="text" :errors="errors" :fields="fields"></vue-textarea>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <vue-textarea label="Informações complementares ao consumidor" name="informacoes_complementares" rows="3" type="text" :errors="errors" :fields="fields"></vue-textarea>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data() {
            return {
                tributacaoIcms: {
                    'simples' : 'Simples nacional',
                    'normal' : 'Normal'
                },
                opcoesSituacaoTributaria: {},
            }
        },
        computed: {
            opcoesSituacaoTributariaSelecionada(){
                if (this.opcoesSituacaoTributaria) {
                    return Object.entries(this.opcoesSituacaoTributaria).map(([value, label]) => ({
                        value,
                        label: label.nome,
                    }))
                }
                return [];
            },
            ...mapState({
                opcoesFormIcms: state => state.options.opcoesFormIcms,
                errors: state => state.nota.errors['impostos'],
                fields: state => state.nota.operacao,
            }),
        },
        mounted() {
            this.opcoesSituacaoTributaria = {
                ...this.opcoesFormIcms['situacao_tributaria']['normal'], 
                ...this.opcoesFormIcms['situacao_tributaria']['simples']
            }
        },
    }
</script>