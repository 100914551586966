import { render, staticRenderFns } from "./FormTransporte.vue?vue&type=template&id=4080c180&"
import script from "./FormTransporte.vue?vue&type=script&lang=js&"
export * from "./FormTransporte.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/bitnami/projects/nuntius-nfe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4080c180')) {
      api.createRecord('4080c180', component.options)
    } else {
      api.reload('4080c180', component.options)
    }
    module.hot.accept("./FormTransporte.vue?vue&type=template&id=4080c180&", function () {
      api.rerender('4080c180', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/nota-fiscal/partial/emitir-notas/FormTransporte.vue"
export default component.exports