var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("vue-retorno-api", { attrs: { retorno: _vm.retorno } }),
          _vm._v(" "),
          _c("vue-datatable", {
            attrs: {
              url: "nota-fiscal/datatable",
              columns: [
                {
                  key: "id",
                  label: "#",
                  width: "5%"
                },
                {
                  key: "ambiente",
                  label: "Ambiente",
                  width: "10%",
                  html: true,
                  method: _vm.ambienteEmissao
                },
                {
                  key: "numero",
                  label: "Número",
                  width: "6%",
                  method: function(numero) {
                    return Number(numero) ? numero : "-"
                  }
                },
                {
                  key: "finalidade",
                  label: "Finalidade",
                  width: "11%",
                  method: _vm.finalidadeNota
                },
                {
                  key: "operacao",
                  label: "Operação",
                  width: "7%",
                  method: _vm.getOpcaoOperacaoText
                },
                {
                  key: "natureza_operacao",
                  label: "Natureza",
                  width: "7%",
                  method: function(natureza) {
                    return natureza || "-"
                  }
                },
                {
                  key: "cliente",
                  label: "Cliente",
                  width: "20%",
                  method: function(cliente) {
                    return cliente || "-"
                  }
                },
                {
                  key: "valor",
                  label: "Valor",
                  width: "8%",
                  method: _vm.maskFloat
                },
                {
                  key: "data",
                  label: "Data",
                  width: "10%",
                  method: _vm.dateBR || "-"
                },
                {
                  key: "status",
                  label: "Status",
                  width: "10%",
                  html: true,
                  method: _vm.statusBadge
                }
              ],
              acoes: {
                ativo: true,
                width: "6%",
                label: "Ações",
                style: "overflow: visible;"
              },
              filterColumns: [
                {
                  name: "id",
                  text: "ID",
                  type: Number
                },
                {
                  name: "dados_formulario->operacao->ambiente",
                  text: "Ambiente",
                  type: "select",
                  options: {
                    "1": "Produção",
                    "2": "Homologação"
                  }
                },
                {
                  name: "dados_retorno->nfe",
                  text: "Número",
                  type: Number
                },
                {
                  name: "dados_envio->finalidade",
                  text: "Finalidade",
                  type: "select",
                  options: {
                    "1": "NF-e normal",
                    "2": "NF-e complementar",
                    "3": "NF-e de ajuste",
                    "4": "Devolução/Retorno",
                    "5": "Carta de Correção",
                    "6": "Inutilizar Numeração"
                  }
                },
                {
                  name: "dados_envio->operacao",
                  text: "Operação",
                  type: "select",
                  options: {
                    "0": "Entrada",
                    "1": "Saída",
                    importacao: "Importação"
                  }
                },
                {
                  name: "dados_envio->natureza_operacao",
                  text: "Natureza",
                  type: String
                },
                {
                  name: "dados_envio->cliente->nome",
                  text: "Cliente",
                  type: String
                },
                {
                  name: "dados_formulario->pedido->total",
                  text: "Valor",
                  type: Number
                },
                {
                  name: "dados_retorno->log->dhRecbto",
                  text: "Data",
                  type: Date
                },
                {
                  name: "dados_retorno->status",
                  text: "Status",
                  type: "select",
                  options: {
                    aprovado: "Aprovado",
                    reprovado: "Reprovado"
                  }
                }
              ],
              predefinedFilters: [
                {
                  val: 1,
                  text: "Ambiente de Produção",
                  type: Number
                },
                {
                  val: 2,
                  text: "Ambiente de Homologação",
                  type: Number
                }
              ],
              tableParams: {
                sort: "id",
                sort_direction: "desc",
                page: 1,
                per_page: 10,
                filter_by: "id",
                filter: "",
                filter_method: "contem"
              },
              checkedFilters: [1]
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.cancelada == false && item.status == "aprovado"
                      ? _c("div", { staticClass: "dropdown text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn pb0 p-0",
                              attrs: {
                                type: "button",
                                id: "dropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false"
                              }
                            },
                            [_c("i", { staticClass: "fas fa-ellipsis-h" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { "aria-labelledby": "dropdownMenuButton" }
                            },
                            [
                              item.finalidade !== "5"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        "data-toggle": "modal",
                                        "data-target": "#myModalDevolucao"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setNota(
                                            item.id,
                                            item.chave,
                                            "devolucao"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Emitir devolução")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    "data-toggle": "modal",
                                    "data-target": "#myModal"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setNota(
                                        item.id,
                                        item.chave,
                                        "cancelamento"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              ),
                              _vm._v(" "),
                              item.danfe
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: item.danfe,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("DANFE")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.xml
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: item.xml,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("XML")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModal == "cancelamento"
        ? _c("modal-cancelamento", {
            key: _vm.idNota,
            attrs: {
              idNota: _vm.idNota,
              chaveNota: _vm.chaveNota,
              onClose: _vm.onClose
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModal == "devolucao"
        ? _c("modal-devolucao", {
            key: _vm.idNota,
            attrs: {
              idNota: _vm.idNota,
              chaveNota: _vm.chaveNota,
              onClose: _vm.onClose
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }