var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body table-responsive p-0" }, [
    _c("table", { staticClass: "table table-hover table-sm" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.titulos, function(titulo) {
            return _c("th", { key: titulo }, [_vm._v(_vm._s(titulo))])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.laravelData.data, function(data) {
            return _c(
              "tr",
              { key: data.id },
              [
                _vm._l(data, function(item) {
                  return _c("td", { key: item }, [_vm._v(_vm._s(item))])
                }),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _c("div", { staticClass: "dropdown" }, [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { "aria-labelledby": "dropdownMenuButton" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              "data-toggle": "modal",
                              "data-target": "#myModal"
                            },
                            on: {
                              click: function($event) {
                                return _vm.editar(data.id)
                              }
                            }
                          },
                          [_vm._v("Editar")]
                        )
                      ]
                    )
                  ])
                ])
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm.loading
            ? _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-center", attrs: { colspan: "5" } },
                  [
                    _c("vue-loading", {
                      attrs: { texto: "Carregando dados..." }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.empty ? _c("tr", [_vm._m(1)]) : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ml-3 mt-3" },
      [
        _c("vue-pagination", {
          attrs: {
            data: _vm.laravelData,
            "show-disabled": false,
            limit: 2,
            size: "default"
          },
          on: { "pagination-change-page": _vm.getResults }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn",
        attrs: {
          type: "button",
          id: "dropdownMenuButton",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "fas fa-ellipsis-h" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-center", attrs: { colspan: "5" } }, [
      _c("h5", [_vm._v("Nenhum dado encontrado")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }