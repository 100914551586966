var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-editable" }, [
    _c("table", { key: "table_vlista_" + _vm.tableKey, staticClass: "table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _vm._l(_vm.columns, function(column, index) {
              return _c("th", { key: index, attrs: { width: column.width } }, [
                _vm._v("\n          " + _vm._s(column.label) + "\n        ")
              ])
            }),
            _vm._v(" "),
            _c("th", { staticClass: "text-right", attrs: { width: "6%" } }, [
              _c("a", {
                staticClass: "pull-right fa fa-plus-circle fa-2x text-success",
                staticStyle: { right: "7px" },
                on: { click: _vm.addLine }
              })
            ])
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._t("body", null, {
            removeLine: _vm.removeLine,
            openModal: _vm.openModal
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }