<template>
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <vue-input label="Nome" :required="true" name="nome" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <vue-input label="Valor" :required="true" name="subtotal" placeholder="0,00" :errors="errors" :fields="fields" ></vue-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="Código do produto" name="codigo" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <vue-label label="Unidade*" name="unidade" :errors="errors">
                        <vue-select-original
                            :value="fields.unidade"
                            @input="fields.unidade = $event"
                            :options="opcoesUnidades"
                            label="label"
                            :reduce="(item) => item.value"
                        />
                    </vue-label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <vue-label label="Origem do Produto*" name="origem" :errors="errors">
                        <vue-select-original
                            :value="fields.origem"
                            @input="fields.origem = $event"
                            :options="opcoesOrigemProduto"
                            label="label"
                            :reduce="(item) => item.value"
                        />
                    </vue-label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="NCM" name="ncm" :required="true" :errors="errors" :fields="fields" v-mask="'####.##.##'"></vue-input>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="CEST" name="cest" v-mask="'#######'" tooltip="O parâmetro CEST deve possuir 7 dígitos" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="GTIN" name="gtin" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <vue-input label="GTIN tributável" name="gtin_tributavel" :errors="errors" :fields="fields"></vue-input>
                </div>
            </div>
        </div>

        <impostos title="Impostos" :errors="errors" :fields="fields"></impostos>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        props: ['fields', 'errors'],
        data() {
            return {
                tributacaoIcms: {
                    'simples' : 'Simples nacional',
                    'normal' : 'Normal'
                }
            }
        },
        computed: {
            ...mapState({
                origemProduto: state => state.options.opcoesOrigemProduto,
                unidades: state => state.configuracoes.opcoesUnidades
            }),
            opcoesUnidades(){
                if (!this.unidades){
                    return [];
                }
                return Object.entries(this.unidades).map(([value, label]) => ({ value, label }));
            },
            opcoesOrigemProduto(){
                if (!this.origemProduto){
                    return [];
                }
                return Object.entries(this.origemProduto).map(([value, label]) => ({ value: parseInt(value), label }));
            }
        },
        methods: {
            ...mapActions(["getOpcoesUnidades"]),
        },
        mounted() {
            this.getOpcoesUnidades();
        },
    }
</script>