export function estados()
{
    return {
        'AC':'Acre',
        'AL':'Alagoas',
        'AP':'Amapá',
        'AM':'Amazonas',
        'BA':'Bahia',
        'CE':'Ceará',
        'DF':'Distrito Federal',
        'ES':'Espírito Santo',
        'GO':'Goiás',
        'MA':'Maranhão',
        'MT':'Mato Grosso',
        'MS':'Mato Grosso do Sul',
        'MG':'Minas Gerais',
        'PA':'Pará',
        'PB':'Paraíba',
        'PR':'Paraná',
        'PE':'Pernambuco',
        'PI':'Piauí',
        'RJ':'Rio de Janeiro',
        'RN':'Rio Grande do Norte',
        'RS':'Rio Grande do Sul',
        'RO':'Rondônia',
        'RR':'Roraima',
        'SC':'Santa Catarina',
        'SP':'São Paulo',
        'SE':'Sergipe',
        'TO':'Tocantins'
    }; 
}

export function paises() {
    return {
        '0132': 'Afeganistão',
        '7560': 'África do Sul',
        '0175': 'Albânia, República da',
        '0230': 'Alemanha',
        '0370': 'Andorra',
        '0400': 'Angola',
        '0418': 'Anguilla',
        '0434': 'Antigua e Barbuda',
        '0477': 'Antilhas Holandesas',
        '0531': 'Arábia Saudita',
        '0590': 'Argélia',
        '0639': 'Argentina',
        '0647': 'Armênia, República da',
        '0655': 'Aruba',
        '0698': 'Austrália',
        '0728': 'Áustria',
        '0736': 'Azerbaijão, República do',
        '0779': 'Bahamas, Ilhas',
        '0809': 'Bahrein, Ilhas',
        '0817': 'Bangladesh',
        '0833': 'Barbados',
        '0850': 'Belarus',
        '0876': 'Bélgica',
        '0884': 'Belize',
        '2291': 'Benin',
        '0906': 'Bermudas',
        '0973': 'Bolívia',
        '0981': 'Bósnia-Herzegovina',
        '1015': 'Botsuana',
        '1058': 'Brasil',
        '1082': 'Brunei',
        '1112': 'Bulgária, República da',
        '0310': 'Burkina Faso',
        '1155': 'Burundi',
        '1198': 'Butão',
        '1279': 'Cabo Verde, República de',
        '1457': 'Camarões',
        '1414': 'Camboja',
        '1490': 'Canadá',
        '1504': 'Canal, Ilhas do (Jersey e Guernsey)',
        '1511': 'Canárias, Ilhas',
        '1546': 'Catar',
        '1376': 'Cayman, Ilhas',
        '1538': 'Cazaquistão, República do',
        '7889': 'Chade',
        '1589': 'Chile',
        '1600': 'China, República Popular da',
        '1635': 'Chipre',
        '5118': 'Christmas, Ilha (Navidad)',
        '7412': 'Cingapura',
        '1651': 'Cocos (Keeling), Ilhas',
        '1694': 'Colômbia',
        '1732': 'Comores, Ilhas',
        '8885': 'Congo, República Democrática do',
        '1775': 'Congo, República do',
        '1830': 'Cook, Ilhas',
        '1872': 'Coréia, Rep. Pop. Democrática da',
        '1902': 'Coréia, República da',
        '1937': 'Costa do Marfim',
        '1961': 'Costa Rica',
        '1988': 'Coveite',
        '1953': 'Croácia, República da',
        '1996': 'Cuba',
        '2321': 'Dinamarca',
        '7838': 'Djibuti',
        '2356': 'Dominica, Ilha',
        '2402': 'Egito',
        '6874': 'El Salvador',
        '2445': 'Emirados Árabes Unidos',
        '2399': 'Equador',
        '2437': 'Eritréia',
        '6289': 'Escócia',
        '2470': 'Eslovaca, República',
        '2461': 'Eslovênia, República da',
        '2453': 'Espanha',
        '2496': 'Estados Unidos',
        '2518': 'Estônia, República da',
        '2534': 'Etiópia',
        '2550': 'Falkland (Ilhas Malvinas)',
        '2593': 'Feroe, Ilhas',
        '8702': 'Fiji',
        '2674': 'Filipinas',
        '2712': 'Finlândia',
        '1619': 'Formosa (Taiwan)',
        '2755': 'França',
        '2810': 'Gabão',
        '6289': 'Gales, País de',
        '2852': 'Gâmbia',
        '2895': 'Gana',
        '2917': 'Geórgia, República da',
        '2933': 'Gibraltar',
        '6289': 'Grã-Bretanha',
        '2976': 'Granada',
        '3018': 'Grécia',
        '3050': 'Groenlândia',
        '3093': 'Guadalupe',
        '3131': 'Guam',
        '3174': 'Guatemala',
        '3379': 'Guiana',
        '3255': 'Guiana Francesa',
        '3298': 'Guiné',
        '3344': 'Guiné-Bissau',
        '3310': 'Guiné-Equatorial',
        '3417': 'Haiti',
        '5738': 'Holanda (Países Baixos)',
        '3450': 'Honduras',
        '3514': 'Hong Kong, Região Adm. Especial',
        '3557': 'Hungria, República da',
        '3573': 'Iêmen',
        '3611': 'Índia',
        '3654': 'Indonésia',
        '6289': 'Inglaterra',
        '3727': 'Irã, República Islâmica do',
        '3697': 'Iraque',
        '3751': 'Irlanda',
        '6289': 'Irlanda do Norte',
        '3794': 'Islândia',
        '3832': 'Israel',
        '3867': 'Itália',
        '3883': 'Iugoslávia, República Fed. da',
        '3913': 'Jamaica',
        '3999': 'Japão',
        '3964': 'Johnston, Ilhas',
        '4030': 'Jordânia',
        '4111': 'Kiribati',
        '4200': 'Laos, Rep. Pop. Democrática do',
        '4235': 'Lebuan',
        '4260': 'Lesoto',
        '4278': 'Letônia, República da',
        '4316': 'Líbano',
        '4340': 'Libéria',
        '4383': 'Líbia',
        '4405': 'Liechtenstein',
        '4421': 'Lituânia, República da',
        '4456': 'Luxemburgo',
        '4472': 'Macau',
        '4499': 'Macedônia',
        '4502': 'Madagascar',
        '4525': 'Madeira, Ilha da',
        '4553': 'Malásia',
        '4588': 'Malavi',
        '4618': 'Maldivas',
        '4642': 'Máli',
        '4677': 'Malta',
        '3595': 'Man, Ilhas',
        '4723': 'Marianas do Norte',
        '4740': 'Marrocos',
        '4766': 'Marshall, Ilhas',
        '4774': 'Martinica',
        '4855': 'Maurício',
        '4880': 'Mauritânia',
        '4936': 'México',
        '0930': 'Mianmar (Birmânia)',
        '4995': 'Micronésia',
        '4901': 'Midway, Ilhas',
        '5053': 'Moçambique',
        '4944': 'Moldávia, República da',
        '4952': 'Mônaco',
        '4979': 'Mongólia',
        '5010': 'Montserrat, Ilhas',
        '5070': 'Namíbia',
        '5088': 'Nauru',
        '5177': 'Nepal',
        '5215': 'Nicarágua',
        '5258': 'Niger',
        '5282': 'Nigéria',
        '5312': 'Niue, Ilha',
        '5355': 'Norfolk, Ilha',
        '5380': 'Noruega',
        '5428': 'Nova Caledônia',
        '5487': 'Nova Zelândia',
        '5568': 'Omã',
        '5738': 'Países Baixos (Holanda)',
        '5754': 'Palau',
        '5800': 'Panamá',
        '5452': 'Papua Nova Guiné',
        '5762': 'Paquistão',
        '5860': 'Paraguai',
        '5894': 'Peru',
        '5932': 'Pitcairn, Ilha',
        '5991': 'Polinésia Francesa',
        '6033': 'Polônia, República da',
        '6114': 'Porto Rico',
        '6076': 'Portugal',
        '6238': 'Quênia',
        '6254': 'Quirguiz, República',
        '6289': 'Reino Unido',
        '6408': 'República Centro-Africana',
        '6475': 'República Dominicana',
        '6602': 'Reunião, Ilha',
        '6700': 'Romênia',
        '6750': 'Ruanda',
        '6769': 'Rússia',
        '6858': 'Saara Ocidental',
        '6777': 'Salomão, Ilhas',
        '6904': 'Samoa',
        '6912': 'Samoa Americana',
        '6971': 'San Marino',
        '7102': 'Santa Helena',
        '7153': 'Santa Lúcia',
        '6955': 'São Cristóvão e Neves',
        '7005': 'São Pedro e Miquelon',
        '7200': 'São Tomé e Príncipe, Ilhas',
        '7056': 'São Vicente e Granadinas',
        '7285': 'Senegal',
        '7358': 'Serra Leoa',
        '7315': 'Seychelles',
        '7447': 'Síria, República Árabe da',
        '7480': 'Somália',
        '7501': 'Sri Lanka',
        '7544': 'Suazilândia',
        '7595': 'Sudão',
        '7641': 'Suécia',
        '7676': 'Suíça',
        '7706': 'Suriname',
        '7722': 'Tadjiquistão',
        '7765': 'Tailândia',
        '7803': 'Tanzânia, República Unida da',
        '7919': 'Tcheca, República',
        '7820': 'Território Britânico Oc. Índico',
        '7951': 'Timor Leste',
        '8001': 'Togo',
        '8109': 'Tonga',
        '8052': 'Toquelau, Ilhas',
        '8150': 'Trinidad e Tobago',
        '8206': 'Tunísia',
        '8230': 'Turcas e Caicos, Ilhas',
        '8249': 'Turcomenistão, República do',
        '8273': 'Turquia',
        '8281': 'Tuvalu',
        '8311': 'Ucrânia',
        '8338': 'Uganda',
        '8451': 'Uruguai',
        '8478': 'Uzbequistão, República do',
        '5517': 'Vanuatu',
        '8486': 'Vaticano, Estado da Cidade do',
        '8508': 'Venezuela',
        '8583': 'Vietnã',
        '8630': 'Virgens, Ilhas (Britânicas)',
        '8664': 'Virgens, Ilhas (E.U.A.)',
        '8737': 'Wake, Ilha',
        '8753': 'Wallis e Futuna, Ilhas',
        '8907': 'Zâmbia',
        '6653': 'Zimbábue',
        '8958': 'Zona do Canal do Panamá'
    };
}

export function origemProduto() {
    return {
        0 : 'Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8',
        1 : 'Estrangeira - Importação direta, exceto a indicada no código 6',
        2 : 'Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
        3 : 'Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
        4 : 'Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
        5 : 'Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
        6 : 'Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural',
        7 : 'Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
        8 : 'Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
    };
}

export function opcoesFormIcms() {
    return {
        'situacao_tributaria' : {
            'normal' : {
                '00' : {
                    'nome' : 'Tributada integralmente',
                    'padrao' : true
                },
               '10' : {
                    'nome' : 'Tributada e com cobrança do ICMS por substituição tributária',
                    'campos' : ['alq_mva', 'aliquota_reducao_st']
                },
                '20' : {
                    'nome' : 'Com redução da base de cálculo',
                    'campos' : ['aliquota_reducao']
                },
                '30' : {
                    'nome' : 'Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
                    'campos' : ['alq_mva', 'aliquota_reducao_st']
                },
                '40' : {
                    'nome' : 'Isenta',
                    'campos' : ['motivo_desoneracao']
                },
                '41' : {
                    'nome' : 'Não tributada',
                    'campos' : ['motivo_desoneracao']
                },
                '50' : {
                    'nome' : 'Suspensão',
                    'campos' : ['motivo_desoneracao']
                },
                '51' : {
                    'nome' : 'Diferimento',
                    'campos' : ['aliquota_diferimento']
                },
                '60' : {'nome' : 'ICMS cobrado anteriormente por substituição tributária'},
                '70' : {
                    'nome' : 'Com redução de base de cálculo e cobrança do ICMS por substituição tributária',
                    'campos' : ['aliquota_reducao', 'alq_mva', 'alq_reducao_st']
                },
                '90' : {
                    'nome' : 'Outros',
                    'campos' : ['aliquota_reducao', 'alq_mva', 'alq_reducao_st']
                }
            },
            'simples' : {
                '101' : {
                    'nome' : 'Tributada com permissão de crédito',
                    'campos' : ['alq_credito']
                },
                '102' : {
                    'nome' : 'Tributada sem permissão de crédito',
                    'padrao' : true
                },
                '103' : {'nome' : 'Isenção do ICMS para faixa de receita bruta'},
                '201' : {
                    'nome' : 'Tributada com permissão de crédito e com cobrança do ICMS por Substituição Tributária',
                    'campos' : ['alq_credito', 'alq_mva', 'alq_reducao_st']
                },
                '202' : {
                    'nome' : 'Tributada sem permissão de crédito e com cobrança do ICMS por substituição tributária',
                    'campos' : ['alq_mva', 'alq_reducao_st']
                },
                '203' : {
                    'nome' : 'Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por substituição tributária',
                    'campos' : ['alq_mva', 'alq_reducao_st']
                },
                '300' : {'nome' : 'Imune'},
                '400' : {'nome' : 'Não tributada'},
                '500' : {'nome' : 'ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'},
                '900' : {
                    'nome' : 'Outros',
                    'campos' : ['alq_credito', 'alq_mva', 'alq_reducao_st']
                }
            }
        },
        'motivos_desoneracao' : {
            '3' : '3 - Uso na agropecuária',
            '9' : '9 - Outros',
            '12' : '12 - Órgão de fomento e desenvolvimento agropecuário'
        }
    };
}

export function opcoesFormIpi() {
    return {
        'situacao_tributaria' : {
            '00' : {'nome' : 'Entrada com recuperação de crédito'},
            '01' : {'nome' : 'Entrada tributada com alíquota zero'},
            '02' : {'nome' : 'Entrada isenta'},
            '03' : {'nome' : 'Entrada não-tributada'},
            '04' : {'nome' : 'Entrada imune'},
            '05' : {'nome' : 'Entrada com suspensão'},
            '49' : {'nome' : 'Outras entradas'},
            '50' : {'nome' : 'Saída tributada'},
            '51' : {'nome' : 'Saída tributada com alíquota zero'},
            '52' : {'nome' : 'Saída isenta'},
            '53' : {'nome' : 'Saída não-tributada'},
            '54' : {'nome' : 'Saída imune'},
            '55' : {'nome' : 'Saída com suspensão'},
            '99' : {
                'nome' : 'Outras saídas',
                'padrao' : true
            },
        },
    };
}

export function opcoesFormPis() {
    return {
        'situacao_tributaria' : {
            '01' : {'nome' : 'Operação Tributável - Base de Cálculo = Valor da Operação Alíquota Normal (Cumulativo/Não cumulativo)'},
            '02' : {'nome' : 'Operação Tributável - Base de Cálculo = Valor da Operação (Alíquota diferenciada)'},
            '03' : {'nome' : 'Operação Tributável - Base de Cálculo = Quantidade Vendida X Alíquota por Unidade de Produto'},
            '04' : {'nome' : 'Operação Tributável - Tributação Monofásica - (Alíquota Zero)'},
            '06' : {'nome' : 'Operação Tributável - Alíquota Zero'},
            '07' : {'nome' : 'Operação Isenta de Contribuição'},
            '08' : {'nome' : 'Operação sem Incidência da Contribuição'},
            '09' : {'nome' : 'Operação com Suspensão da Contribuição'},
            '49' : {'nome' : 'Outras Operações de Saída'},
            '50' : {'nome' : 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
            '51' : {'nome' : 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno'},
            '52' : {'nome' : 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação'},
            '53' : {'nome' : 'Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
            '54' : {'nome' : 'Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
            '55' : {'nome' : 'Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
            '56' : {'nome' : 'Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'},
            '60' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
            '61' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'},
            '62' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação'},
            '63' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
            '64' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
            '65' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
            '66' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'},
            '67' : {'nome' : 'Crédito Presumido - Outras Operações'},
            '70' : {'nome' : 'Operação de Aquisição sem Direito a Crédito'},
            '71' : {'nome' : 'Operação de Aquisição com Isenção'},
            '72' : {'nome' : 'Operação de Aquisição com Suspensão'},
            '73' : {'nome' : 'Operação de Aquisição a Alíquota Zero'},
            '74' : {'nome' : 'Operação de Aquisição sem Incidência da Contribuição'},
            '75' : {'nome' : 'Operação de Aquisição por Substituição Tributária'},
            '98' : {'nome' : 'Outras Operações de Entrada'},
            '99' : {
                'nome' : 'Outras Operações',
                'padrao' : true
            },
        },
    };
}

export function opcoesFormCofins() {
    return {
        'situacao_tributaria' : {
            '01' : {'nome' : 'Operação Tributável - Base de Cálculo = Valor da Operação Alíquota Normal (Cumulativo/Não cumulativo)'},
            '02' : {'nome' : 'Operação Tributável - Base de Cálculo = Valor da Operação (Alíquota diferenciada)'},
            '03' : {'nome' : 'Operação Tributável - Base de Cálculo = Quantidade Vendida X Alíquota por Unidade de Produto'},
            '04' : {'nome' : 'Operação Tributável - Tributação Monofásica - (Alíquota Zero)'},
            '06' : {'nome' : 'Operação Tributável - Alíquota Zero'},
            '07' : {'nome' : 'Operação Isenta de Contribuição'},
            '08' : {'nome' : 'Operação sem Incidência da Contribuição'},
            '09' : {'nome' : 'Operação com Suspensão da Contribuição'},
            '49' : {'nome' : 'Outras Operações de Saída'},
            '50' : {'nome' : 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
            '51' : {'nome' : 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno'},
            '52' : {'nome' : 'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação'},
            '53' : {'nome' : 'Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
            '54' : {'nome' : 'Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
            '55' : {'nome' : 'Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
            '56' : {'nome' : 'Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'},
            '60' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
            '61' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'},
            '62' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação'},
            '63' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
            '64' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
            '65' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
            '66' : {'nome' : 'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'},
            '67' : {'nome' : 'Crédito Presumido - Outras Operações'},
            '70' : {'nome' : 'Operação de Aquisição sem Direito a Crédito'},
            '71' : {'nome' : 'Operação de Aquisição com Isenção'},
            '72' : {'nome' : 'Operação de Aquisição com Suspensão'},
            '73' : {'nome' : 'Operação de Aquisição a Alíquota Zero'},
            '74' : {'nome' : 'Operação de Aquisição sem Incidência da Contribuição'},
            '75' : {'nome' : 'Operação de Aquisição por Substituição Tributária'},
            '98' : {'nome' : 'Outras Operações de Entrada'},
            '99' : {
                'nome' : 'Outras Operações',
                'padrao' : true
            },
        },
    };
}

export function opcoesFormImportacao() {
    return {
        'vias_trasporte' : {
            1 : {'nome' : 'Marítima'},
            2 : {'nome' : 'Fluvial'},
            3 : {'nome' : 'Lacustre'},
            4 : {'nome' : 'Aérea'},
            5 : {'nome' : 'Postal'},
            6 : {'nome' : 'Ferroviária'},
            7 : {'nome' : 'Rodoviária'},
            8 : {'nome' : 'Conduto / Rede Transmissão'},
            9 : {'nome' : 'Meios Próprios'},
            10 : {'nome' : 'Entrada / Saída ficta'},
            11 : {'nome' : 'Courier'},
            12 : {'nome' : 'Handcarry'},
        },
        'formas_intermediacao' : {
            1 : {'nome' : 'Importação por conta própria'},
            2 : {'nome' : 'Importação por conta e ordem'},
            3 : {'nome' : 'Importação por encomenda'},
        }
    };
}

export function opcoesFormExportacao() {
    return {
        'tipos_exportacao' : {
            'direta' : {'nome' : 'Direta'},
            'indireta' : {'nome' : 'Indireta'},
            'drawback' : {'nome' : 'Drawback'},
        }
    };
}

export function opcoesFormDetalhamento() {
    return {
        'tipos_produto' : {
            'combustiveis' : {'nome' : 'Combustíveis'},
            'medicamento' : {'nome' : 'Medicamento'},
            'armamento' : {'nome' : 'Armamento'},
            'veiculos' : {'nome' : 'Veículos'},
            'energia' : {'nome' : 'Energia Elétrica'},
        }
    };
}

export function opcoesOperacao() {
    return {
        1 : 'Saída',
        0 : 'Entrada',
        'importacao' : 'Importação',
        // 'exportacao' : 'Exportação'
    }
}