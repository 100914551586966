var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "#.###",
                    expression: "'#.###'"
                  }
                ],
                attrs: {
                  label: "CFOP",
                  name: "codigo_cfop",
                  type: "text",
                  required: "vuelidate",
                  errors: _vm.errors,
                  fields: _vm.impostos
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("vue-checkbox", {
        attrs: {
          label: "Complementar ICMS",
          name: "show_form_icms",
          value: "form_icms",
          onchange: _vm.toggleForm,
          fields: _vm.impostos
        }
      }),
      _vm._v(" "),
      _vm.form_icms
        ? _c("div", [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("vue-select", {
                      attrs: {
                        label: "Situação tributária",
                        name: "situacao_tributaria_icms",
                        required: "vuelidate",
                        options: _vm.opcoesSituacaoTributaria,
                        text_key: "nome",
                        show_key: true,
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Base de cálculo",
                        name: "bc_icms",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Valor do complemento",
                        name: "valor_icms",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("vue-checkbox", {
        attrs: {
          label: "Complementar ICMS ST",
          name: "show_form_icms_st",
          value: "form_icms_st",
          onchange: _vm.toggleForm,
          fields: _vm.impostos
        }
      }),
      _vm._v(" "),
      _vm.form_icms_st
        ? _c("div", [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("vue-select", {
                      attrs: {
                        label: "Situação tributária",
                        name: "situacao_tributaria_icms_st",
                        required: "vuelidate",
                        options: _vm.opcoesSituacaoTributaria,
                        text_key: "nome",
                        show_key: true,
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Base de cálculo",
                        name: "bc_icms_st",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Valor do complemento",
                        name: "valor_icms_st",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Alíquota MVA",
                        name: "aliquota_mva",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("vue-checkbox", {
        attrs: {
          label: "Complementar IPI",
          name: "show_form_ipi",
          value: "form_ipi",
          onchange: _vm.toggleForm,
          fields: _vm.impostos
        }
      }),
      _vm._v(" "),
      _vm.form_ipi
        ? _c("div", [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Base de cálculo",
                        name: "bc_ipi",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("vue-input", {
                      attrs: {
                        label: "Valor do complemento do IPI",
                        name: "valor_ipi",
                        type: "text",
                        required: "vuelidate",
                        errors: _vm.errors,
                        fields: _vm.impostos
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }