var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.active === true ? "tab-pane fade active show" : "tab-pane fade",
      attrs: {
        id: "content-" + _vm.id,
        role: "tabpanel",
        "aria-labelledby": _vm.id
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }