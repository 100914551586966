var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "icheck-primary", class: _vm.custonControlInline },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fields[_vm.name],
              expression: "fields[name]"
            }
          ],
          attrs: { type: "checkbox", id: "checkbox-" + _vm.value },
          domProps: {
            value: _vm.value,
            checked: Array.isArray(_vm.fields[_vm.name])
              ? _vm._i(_vm.fields[_vm.name], _vm.value) > -1
              : _vm.fields[_vm.name]
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.fields[_vm.name],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.fields, _vm.name, $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.fields,
                        _vm.name,
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.fields, _vm.name, $$c)
                }
              },
              function($event) {
                _vm.onchange !== undefined ? _vm.onchange(_vm.value) : "#"
              }
            ]
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "checkbox" + "-" + _vm.value } }, [
          _vm._v("\n            " + _vm._s(_vm.label) + "\n        ")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }