var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota do imposto de importação",
                name: "aliquota",
                placeholder: "0,00",
                value: "0",
                errors: _vm.errors,
                fields: _vm.fields.impostos.importacao
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota do IOF",
                name: "iof",
                placeholder: "0,00",
                errors: _vm.errors,
                fields: _vm.fields.impostos.importacao
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "border-bottom mb-3 mt-2" }, [
      _vm._v("Documento de importação")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Nº do documento",
                tooltip: "(DI, DSI, DIRE, ...)",
                name: "ndoc_importacao",
                type: "number",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Data de registro do documento",
                name: "ddoc_importacao",
                type: "date",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Estado do desembaraço",
                name: "uf_desembaraco",
                options: _vm.estados,
                select_label: "Selecione",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Local do desembaraço",
                name: "local_desembaraco",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Data do desembaraço",
                name: "data_desembaraco",
                type: "date",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Via de transporte",
                name: "via_transporte",
                options: _vm.opcoesViasTransporte,
                select_label: "Selecione",
                text_key: "nome",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Forma de intermediação",
                name: "intermediacao",
                options: _vm.opcoesFormasIntermediacao,
                select_label: "Selecione",
                text_key: "nome",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Cód. do fabricante",
                tooltip:
                  "Número determinado pelo importador, verificar junto ao ERP/Sistema o número de cadastro do fabricante.",
                name: "fabricante",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "border-bottom mb-3 mt-2" }, [_vm._v("Adição")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Nº da adição",
                name: "adicao",
                type: "number",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Nº sequencial do item dentro da adição",
                name: "seq_adicao",
                type: "number",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "border-bottom mb-3 mt-2" }, [_vm._v("Outros")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              directives: [
                {
                  name: "money",
                  rawName: "v-money",
                  value: _vm.money,
                  expression: "money"
                }
              ],
              attrs: {
                label: "Valor da AFRMM",
                tooltip:
                  "Deve ser informada no caso da via de transporte marítima.",
                name: "afrmm",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "CNPJ do adquirente ou do encomendante",
                tooltip:
                  "Obrigatória a informação no caso de importação por conta e ordem ou por encomenda. Informar os zeros não significativos.",
                name: "cnpj_terceiro",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "UF do adquirente",
                name: "uf_terceiro",
                tooltip:
                  "Obrigatória a informação no caso de importação por conta e ordem ou por encomenda.",
                options: _vm.estados,
                select_label: "Selecione",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Cód. do exportador",
                name: "cod_exportador",
                tooltip:
                  "Número determinado pelo importador, verificar junto ao ERP/Sistema o número de cadastro do exportador.",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Nº de controle da FCI",
                name: "nfci",
                tooltip:
                  "Informação relacionada com a Resolução 13/2012 do Senado Federal.",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }