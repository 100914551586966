var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { attrs: { for: _vm.name } }, [
          _vm._v(_vm._s(_vm.required ? _vm.label + "*" : _vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.fields[_vm.name],
          expression: "fields[name]"
        }
      ],
      staticClass: "form-control",
      class: { "border-danger": _vm.errors && _vm.errors[_vm.name] },
      attrs: {
        id: _vm.name,
        required: _vm.required && _vm.required != "vuelidate",
        name: _vm.name,
        rows: _vm.rows,
        disabled: _vm.loading
      },
      domProps: { value: _vm.fields[_vm.name] },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.fields, _vm.name, $event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _vm.errors && _vm.errors[_vm.name]
      ? _c("div", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errors[_vm.name][0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }