var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", [_vm._v(_vm._s(_vm.texto))]),
      _vm._v(" "),
      _c("loading", {
        attrs: {
          active: _vm.isLoading,
          "can-cancel": true,
          "on-cancel": _vm.onCancel,
          "is-full-page": _vm.fullPage
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }