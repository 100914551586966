var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _vm.form_emissao != "estrangeiro"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "form-group ml-2" },
                [
                  _c("vue-radio", {
                    attrs: {
                      name: "tipo_pessoa",
                      options: JSON.stringify(_vm.tipos_pessoa),
                      inline: true,
                      onchange: _vm.changeForm,
                      errors: _vm.errors,
                      fields: _vm.cliente
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.form_emissao == "pessoa-fisica"
        ? _c("form-pessoa-fisica", {
            attrs: {
              show_consumidor_final: true,
              errors: _vm.errors,
              fields: _vm.cliente
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.form_emissao == "pessoa-juridica"
        ? _c("form-pessoa-juridica", {
            attrs: {
              show_consumidor_final: true,
              errors: _vm.errors,
              fields: _vm.cliente
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.form_emissao == "estrangeiro"
        ? _c("form-estrangeiro", {
            attrs: {
              show_consumidor_final: true,
              errors: _vm.errors,
              fields: _vm.cliente
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }