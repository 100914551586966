const configuracoes = {

    state: {
        empresa: {},
        empresas: {},
        opcoesEmpresas: {},
        loading: false
    },
    
    getters: {
        getEmpresa: state => {
            return state.empresa
        },
    },
    
    mutations: {
        SET_LOADING (state, loading) {
            state.loading = loading
        },
        SET_EMPRESAS(state, empresas) {
            state.empresas = empresas
        },
        SET_OPCOES_EMPRESAS(state, opcoesEmpresas) {
            state.opcoesEmpresas = opcoesEmpresas
        },
        SET_EMPRESA(state, empresa) {
            state.empresa = empresa
        }
    },
    
    actions: {
        async getEmpresas({commit}) {
            commit('SET_LOADING', true)
            const response = await axios.get('empresas')
            commit('SET_EMPRESAS', response.data)
            commit('SET_LOADING', false)
        },
        async getOpcoesEmpresas({commit}) {
            commit('SET_LOADING', true)
            const response = await axios.get('empresas?select=1')
            commit('SET_OPCOES_EMPRESAS', response.data)
            commit('SET_LOADING', false)
        },
    }

}

export default configuracoes;
