var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { attrs: { for: _vm.name } }, [
          _vm._v(_vm._s(_vm.required ? _vm.label + "*" : _vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.tooltip
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip:top",
              value: _vm.tooltip,
              expression: "tooltip",
              arg: "top"
            }
          ],
          staticClass: "fa fa-question-circle"
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.lazy",
          value: _vm.fields[_vm.name],
          expression: "fields[name]",
          modifiers: { lazy: true }
        }
      ],
      staticClass: "form-control",
      class: [
        { "border-danger": _vm.errors && _vm.errors[_vm.name] },
        _vm.classes
      ],
      attrs: {
        type: _vm.type,
        placeholder: _vm.placeholder,
        name: _vm.id !== undefined ? _vm.name + "_" + _vm.id : _vm.name,
        required: _vm.required && _vm.required != "vuelidate",
        disabled: _vm.disabled || _vm.loading
      },
      domProps: { value: _vm.fields[_vm.name] },
      on: {
        change: [
          function($event) {
            return _vm.$set(_vm.fields, _vm.name, $event.target.value)
          },
          _vm.change
        ]
      }
    }),
    _vm._v(" "),
    _vm.errors && _vm.errors[_vm.name]
      ? _c("div", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errors[_vm.name][0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }