var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota estimada de Tributos Federais",
                tooltip:
                  "Consulte a porcentagem no IBPT: https://deolhonoimposto.ibpt.org.br",
                name: "tributos_federais",
                placeholder: "0,00",
                value: "0",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota estimada de Tributos Estaduais",
                tooltip:
                  "Consulte a porcentagem no IBPT: https://deolhonoimposto.ibpt.org.br",
                name: "tributos_estaduais",
                placeholder: "0,00",
                value: "0",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }