<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Relatórios</h3>
        </div>
        <form @submit.prevent="submit">
            <div class="card-body">
                <vue-loading v-if="carregando" texto="Carregando dados..."></vue-loading>
                <div class="alert alert-danger" v-if="this.errors && this.errors.response">{{ this.errors.response }}</div>
                <div class="row mb-2">
                    <div class="col">
                        <vue-label label="Empresa" name="empresa" :errors="errors">
                            <vue-select-original
                                name="relatorio"
                                :value="filtros.empresa_id"
                                @input="filtros.empresa_id = $event"
                                :options="empresasList"
                                label="nome_fantasia"
                                :reduce="(item) => item.id"
                            />
                        </vue-label>
                    </div>
                    <div class="col">
                        <vue-input label="Data inicial" name="data_inicio" type="date" :errors="errors" :fields="filtros"></vue-input>
                    </div>
                    <div class="col">
                        <vue-input label="Data final" name="data_final" type="date" :errors="errors" :fields="filtros"></vue-input>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <vue-label label="Modelo" name="modelo" :errors="errors">
                            <vue-select-original
                                name="modelo"
                                :value="filtros.modelo"
                                @input="filtros.modelo = $event"
                                :options="getModelos"
                                label="value"
                                :reduce="(item) => item.key"
                            />
                        </vue-label>
                    </div>
                    <div class="col">
                        <vue-label label="Relatório" name="relatorio" :errors="errors">
                            <vue-select-original
                                name="relatorio"
                                :value="filtros.relatorio"
                                @input="filtros.relatorio = $event"
                                :options="getRelatorios"
                                label="value"
                                :reduce="(item) => item.key"
                            />
                        </vue-label>
                    </div>
                    <div class="col">
                        <vue-label label="Status" name="status" :errors="errors">
                            <vue-select-original
                                name="status"
                                :value="filtros.status"
                                @input="filtros.status = $event"
                                :options="getStatus"
                                label="value"
                                :reduce="(item) => item.key"
                            />
                        </vue-label>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-primary float-right">Enviar</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapState({
                filtros: state => state.relatorios.filtros,
                errors: state => state.relatorios.errors,
                carregando: state => state.relatorios.carregando,
                empresas: state => state.empresa.empresas,
            }),
            ...mapGetters(["getModelos", "getRelatorios", "getStatus"]),
            empresasList(){
                if (Array.isArray(this.empresas)){
                    return this.empresas;
                }
                return [];
            }
        },
        methods: {
            ...mapActions(["submit", "getEmpresas"]),
        },
        created() {
            this.getEmpresas();
        },
    }
</script>

<style scoped>
    .card-title {
        font-size: 1.5rem;
    }
</style>
