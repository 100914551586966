var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-body" },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("h3", { staticClass: "card-title" }, [_vm._v("Empresas")]),
        _vm._v(" "),
        _c("div", { staticClass: "card-tools" }, [
          _c(
            "div",
            {
              staticClass: "input-group input-group-sm",
              staticStyle: { width: "110px" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-sm bg-gradient-primary",
                  attrs: {
                    type: "button",
                    "data-toggle": "modal",
                    "data-target": "#modal-empresa"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setEmpresa(null)
                    }
                  }
                },
                [_vm._v("Cadastrar nova")]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table m-0" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.empresas, function(empresa) {
              return _c("tr", { key: empresa.id }, [
                _c("td", [_vm._v(_vm._s(empresa.id))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(empresa.nome_fantasia))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(empresa.razao_social))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(empresa.cpf_cnpj))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _c("div", { staticClass: "dropdown" }, [
                    _vm._m(1, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { "aria-labelledby": "dropdownMenuButton" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              "data-toggle": "modal",
                              "data-target": "#modal-empresa"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setEmpresa(empresa.id)
                              }
                            }
                          },
                          [_vm._v("Editar")]
                        )
                      ]
                    )
                  ])
                ])
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("modal-empresa", {
        attrs: { onClose: _vm.onClose, empresa_id: _vm.empresa_id }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nome fantasia")]),
        _vm._v(" "),
        _c("th", [_vm._v("Razão social")]),
        _vm._v(" "),
        _c("th", [_vm._v("CPF/CNPJ")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn",
        attrs: {
          type: "button",
          id: "dropdownMenuButton",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "fas fa-ellipsis-h" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }