var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(JSON.parse(_vm.options), function(option, key) {
        return _c(
          "div",
          {
            key: key,
            staticClass: "custom-control custom-radio",
            class: _vm.custonControlInline
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fields[_vm.name],
                  expression: "fields[name]"
                }
              ],
              staticClass: "custom-control-input",
              attrs: {
                type: "radio",
                id: _vm.name + "_" + key,
                name: _vm.name
              },
              domProps: {
                value: key,
                checked: _vm._q(_vm.fields[_vm.name], key)
              },
              on: {
                change: [
                  function($event) {
                    return _vm.$set(_vm.fields, _vm.name, key)
                  },
                  _vm.onchange
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: _vm.name + "_" + key }
              },
              [_vm._v(_vm._s(option))]
            )
          ]
        )
      }),
      _vm._v(" "),
      _vm.errors && _vm.errors.name
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.errors[_vm.name][0]))
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }