Vue.mixin({
    created() {
        this.$toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "200",
            "hideDuration": "200",
            "timeOut": "0",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            "closeMethod": 'fadeOut'
        }
    },
    methods: {
        showErrorMessage(text, title = 'Erro!') {
            this.$toastr.error(text, title)
        },
        showSuccessMessage(text, title = 'Sucesso!') {
            this.$toastr.success(text, title)
        },
        showWarningMessage(text, title = 'Atenção') {
            this.$toastr.warning(text, title)
        },
        showInfoMessage(text, title = null) {
            this.$toastr.info(text, title)
        },
        returnMessage(retorno) {
            if(retorno.hasOwnProperty('success')) {
                this.showSuccessMessage(retorno.success);
            } else if(retorno.hasOwnProperty('error')) {
                this.showErrorMessage(retorno.error);
                console.error(retorno.errorDescription);
            }
        }
    }
});