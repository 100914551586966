var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select-nuntius",
    {
      attrs: {
        label: _vm.label,
        filterable: false,
        options: _vm.options,
        disabled: _vm.disabled,
        maxHeight: _vm.maxHeight,
        clearable: _vm.clearable,
        required: _vm.required
      },
      on: { search: _vm.onSearch, input: _vm.send },
      model: {
        value: _vm.selected,
        callback: function($$v) {
          _vm.selected = $$v
        },
        expression: "selected"
      }
    },
    [
      _c("template", { slot: "no-options" }, [
        _vm._v("\n        " + _vm._s(_vm.noOptions) + "\n    ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }