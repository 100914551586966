var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "vue-label",
              {
                attrs: {
                  label: "Situação tributária*",
                  name: "situacao_tributaria",
                  errors: _vm.errors
                }
              },
              [
                _c("vue-select-original", {
                  attrs: {
                    value: _vm.fields.situacao_tributaria,
                    options: _vm.opcoesSituacaoTributariaSelecionada,
                    label: "label",
                    required: "vuelidate",
                    reduce: function(item) {
                      return item.value
                    }
                  },
                  on: {
                    input: function($event) {
                      _vm.fields.situacao_tributaria = $event
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "#.###",
                  expression: "'#.###'"
                }
              ],
              attrs: {
                label: "Código CFOP de ajuste",
                name: "codigo_cfop",
                placeholder: "_.___",
                type: "text",
                required: "vuelidate",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Valor do ICMS a ser ajustado",
                name: "valor_icms",
                type: "text",
                required: "vuelidate",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-textarea", {
              attrs: {
                label: "Informações ao fisco",
                name: "informacoes_fisco",
                rows: "3",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-textarea", {
              attrs: {
                label: "Informações complementares ao consumidor",
                name: "informacoes_complementares",
                rows: "3",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }