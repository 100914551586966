var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "vue-label",
              {
                attrs: {
                  label: "Tributação*",
                  name: "tipo_tributacao",
                  errors: _vm.errors
                }
              },
              [
                _c("vue-select-original", {
                  attrs: {
                    value: _vm.fields.tipo_tributacao,
                    options: _vm.opcoesSituacaoTributaria,
                    label: "label",
                    reduce: function(item) {
                      return item.value
                    }
                  },
                  on: {
                    input: function($event) {
                      return _vm.setTipoTributacao($event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "CFOP",
                name: "codigo_cfop",
                type: "text",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "vue-label",
              {
                attrs: {
                  label: "Situação tributária*",
                  name: "situacao_tributaria",
                  errors: _vm.errors
                }
              },
              [
                _c("vue-select-original", {
                  attrs: {
                    value: _vm.fields.situacao_tributaria,
                    options: _vm.opcoesSituacaoTributariaSelecionada,
                    label: "label",
                    reduce: function(item) {
                      return item.value
                    }
                  },
                  on: {
                    input: function($event) {
                      return _vm.selectSituacaoTributaria($event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-select", {
              attrs: {
                label: "Indústria",
                name: "industria",
                options: _vm.opcoesIndustria,
                tooltip:
                  "Informe caso a venda do produto seja destinada a uma indústria para o uso e consumo final.",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota da redução da base de cálculo ICMS",
                name: "aliquota_reducao",
                placeholder: "0,00",
                value: "0",
                required: true,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota da redução da base de cálculo ICMS ST",
                name: "aliquota_reducao_st",
                placeholder: "0,00",
                value: "0",
                required: true,
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.showTabImportacao
        ? _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-input", {
                  attrs: {
                    label: "Alíquota de ICMS + FCP de importação",
                    name: "aliquota_importacao",
                    value: "0",
                    tooltip:
                      "Para o cálculo correto é necessário informar a alíquota integral do ICMS, sendo o FCP para o estado de destino calculado automaticamente.",
                    placeholder: "0,00",
                    errors: _vm.errors,
                    fields: _vm.fields
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.situacaoTributariaCampos.includes("alq_credito")
        ? _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-input", {
                  attrs: {
                    label: "Alíquota aplicável de cálculo de crédito",
                    name: "alq_credito",
                    placeholder: "0,00",
                    required: true,
                    errors: _vm.errors,
                    fields: _vm.fields
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.situacaoTributariaCampos.includes("alq_mva")
        ? _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-input", {
                  attrs: {
                    label: "Percentual do MVA/IVA Original",
                    tooltip:
                      "A definição da MVA Ajustada é realizada automaticamente.",
                    name: "alq_mva",
                    placeholder: "0,00",
                    required: true,
                    errors: _vm.errors,
                    fields: _vm.fields
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.situacaoTributariaCampos.includes("motivo_desoneracao")
        ? _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "Motivo da desoneração",
                    tooltip:
                      "Escolha um motivo somente se enquadrar em algum dos listados.",
                    name: "motivo_desoneracao",
                    select_label: "--",
                    options: this.opcoes["motivos_desoneracao"],
                    errors: _vm.errors,
                    fields: _vm.fields
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.situacaoTributariaCampos.includes("aliquota_diferimento")
        ? _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("vue-input", {
                  attrs: {
                    label: "Percentual do diferimento",
                    name: "aliquota_diferimento",
                    placeholder: "0,00",
                    errors: _vm.errors,
                    fields: _vm.fields
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("vue-input", {
            attrs: {
              label: "Alíquota de importação",
              name: "aliquota_importacao",
              placeholder: "0,00",
              errors: _vm.errors,
              fields: _vm.fields
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota de MVA",
                name: "aliquota_mva",
                placeholder: "0,00",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-input", {
              attrs: {
                label: "Alíquota efetiva ICMS ST",
                name: "aliquota_efetiva_icms_st",
                placeholder: "0,00",
                errors: _vm.errors,
                fields: _vm.fields
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }